import React, { useState } from 'react';
import { ContentHeader, Button, FilterComponent, SmallBox, ComboBox } from '@components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ReportsPromotion from '@pages/promotions/ReportsTickets'
import ReportsPoints from '@pages/promotions/ReportsPoints'

const types = [
    {
        id: 0,
        name: "Por Código",
    },
    {
        id: 1,
        name: "Por Ticket",
    }
];

const Promotions = () => {
    const [t] = useTranslation();
    const [promotionType, setPromotionType] = useState(0)
    const dispatch = useDispatch();

    return (
        <div className="container-fluid">

            <div className="row">
                <div className="col-sm-2">
                    <ContentHeader title={t('menusidebar.label.reports')} />
                </div>
                <div className="col-sm-4 mb-2">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-10">
                                    <ComboBox
                                        items={types}
                                        optionTitle="name"
                                        messageOptionDefault="Seleccione un tipo"
                                        value={promotionType}
                                        onChange={value => setPromotionType(value.id)}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {promotionType === 0 ?
                <ReportsPoints /> :
                <ReportsPromotion />}
            
        </div>
    );


};

export default Promotions;