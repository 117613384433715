import Api from '../utils/axios';
const api = new Api();

export const getNotifications = async () => {
    const notifications = await api.getNotifications()
    return notifications.data;
}

export const createNotifications = async (data) => {
    const CreatedNotification = await api.createNotifications(data);
    console.log(CreatedNotification)
    return CreatedNotification;
}




export const getNotificationById = async (id) => {
    const response = await api.getNotificationById(id);
    console.log(response)
    return response
}