import React, { useState, useEffect } from 'react';
import { Button, Input, ComboBox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as tagsServices from '../../services/tags';
import * as Yup from 'yup';
import Api from '../../utils/axios'


const EditTag = () => {
    const history = useHistory();
    const [tags, setTags] = useState(false);
    const [tag, setTag] = useState();
    const { id } = useParams()
    const [t] = useTranslation();

    useEffect(() => {
        tagsServices.getTagById(id)
            .then((response) => {
                console.log(response)
                formik.setValues({ ...response.data })
                setTag(response)

            }).catch(error => {
                console.log(error)
            })
    }, [])



    const formik = useFormik({
        initialValues: {
            name: '',
            createdAt: null,
            updatedAt: null

        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .required(t('validationSchema.categoryNameRequired'))
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    const usersData = async (data) => {
        try {
            const response = await tagsServices.updateTags(id, data);
            console.log(response)
            toast.success(t('news.label.tagEdit'));
            history.push('/news/categories');
        } catch (error) {
            setTags(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleDeleteTag = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await tagsServices.deleteTag(id)
                toast.success(t('news.label.tagDeleted'));
                history.push('/news/categories');
            } else {
                return false;
            }

        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('news.label.editTag')}</h3>
                        {/*<div class="mr-1 card-tools">
                            <Button
                                isLoading={tags}
                                type="submit" block
                                onClick={handleDeleteTag}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>*/}
                    </div>
                    <div className="p-3">
                        {tag ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('news.label.nameTag')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        value={tag.name}
                                        placeholder={t('news.label.nameTag')} />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/news/categories`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        isLoading={tags}
                                        type="submit" block
                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditTag;