import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, ImageUpload } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import Api from '../../utils/axios'
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import * as NewsServices from '../../services/news';

const CreateNews = () => {
    const [t] = useTranslation();
    const api = new Api();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const [categories, setCategories] = useState();
    const [tagId, setIdTag] = useState(1);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: "",
            autor: "",
            details: "",
            context: "",
            coverImageId: null,
            readingTime: "",
            video: "",
            tagId: 0
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .required(t('validationSchema.nameNew')),
            autor: Yup.string()
                .required(t('validationSchema.nameAuthor')),
            details: Yup.string()
                .min(50, t('validationSchema.min50Caracters'))
                .max(450, t('validationSchema.max450Caracters'))
                .required(t('validationSchema.detailsNewsRequired')),
            context: Yup.string()
                .min(130, t('validationSchema.min130Caracters'))
                .max(3000, t('validationSchema.max3000Caracters'))
                .required(t('validationSchema.infoNewRequired')),
            readingTime: Yup.string()
                .matches(/^([0-9])([0-9]):([0-9])([0-9])/, t('validationSchema.formartInvalid'))
                .required(t('validationSchema.redingTimeRequired')),
            tagId: Yup.string()
                .required(t('validationSchema.categoriRequired')),
           
        }),
        onSubmit: (values) => {
            seenDataNews(values);
        }
    });

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData)
            setUploadImage(response.data.file)
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
        }
    }

    const seenDataNews = async (data) => {

        if (uploadImage == null) {
            toast.error(t('general.label.needImageToContinue'));
            return
        }
        data.context.data2
        data.tagId = tagId;
        data.coverImageId = uploadImage.id;

        try {
            dispatch(setLoading(true));
            setSaveLoading(true);
            await NewsServices.createNews(data);
            toast.success(t('news.label.newCreated'));
            dispatch(setLoading(false));
            history.push('/news');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    useEffect(() => {
        dispatch(setLoading(true));
        api
            .getTags()
            .then((response) => {
                setCategories(response.data)
                dispatch(setLoading(false));
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error)
            })
    }, [])

    

    return (
        <div>
            {/*<ContentHeader title={t('menusidebar.label.news')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('news.label.createNew')}</h3>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.nameNew')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Input
                                                placeholder={t('news.label.nameNew')}
                                                type="name"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('name')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.authorName')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Input
                                                placeholder={t('news.label.authorName')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('autor')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.abstract')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('news.label.abstract')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('details')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.context')}
                                        </label>
                                        <div className="col-sm-10">

                                            <Textarea
                                                placeholder={t('news.label.contextDetail')}
                                                as="textarea"
                                                formik={formik}
                                                isRichText={true}
                                                formikFieldProps={formik.getFieldProps('context')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row mt-2 mb-2">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('news.label.category')}
                                        </label>
                                        {categories && categories.length > 0 ? (
                                            <div className="col-sm-4">
                                                <ComboBox
                                                    items={categories}
                                                    optionTitle="name"
                                                    messageDefault="Seleccione una categoría"
                                                    onChange={(item) => setIdTag(item.id)}
                                                    value={tagId}
                                                    formik={formik}
                                                    formikFieldProps={formik.getFieldProps('tagId')} />
                                            </div>
                                        ) : null}

                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-4 col-form-label"
                                        >
                                            {t('news.label.readingTime')}
                                        </label>
                                        <div className="col-sm-2">
                                            <Input
                                                placeholder="Ejm. 02:30"
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('readingTime')}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <ImageUpload
                                        id="myImage"
                                        label={t('news.label.imageRequired')}
                                        image={uploadImage}
                                        witdh={960}
                                        height={640}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/news`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('general.buttons.createNew')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNews;