import {createSlice} from '@reduxjs/toolkit';
import {calculateWindowSize} from '@app/utils/helpers';

const initialState = {
    isSidebarMenuCollapsed: false,
    screenSize: calculateWindowSize(window.innerWidth),
    loading: false
};

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggleSidebarMenu: (state) => {
            state.isSidebarMenuCollapsed = !state.isSidebarMenuCollapsed;
        },
        setWindowSize: (state, {payload}) => {
            state.screenSize = payload;
        },
        setLoading: (state, { payload }) => {
            state.loading = payload;
        }
    }
});

export const { toggleSidebarMenu, setWindowSize, setLoading} = uiSlice.actions;

export default uiSlice.reducer;
