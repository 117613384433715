import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, ContentHeader, Button } from '@components';
import { Link, useParams, useHistory } from 'react-router-dom';
import * as ticketService from '../../services/tickets';
import Spinner from 'react-bootstrap/Spinner'
import Api from '../../utils/axios'
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import prettyDate from '../../utils/pretty-date'


const ProductList = () => {
    const [t] = useTranslation();
    const api = new Api();
    const [productList, setProductList] = useState([])
    const [ticketId, setTicketId] = useState()
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams()

    const columns = useMemo(() => [
        {
            name: 'Id',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Canal',
            selector: row => row.shopName === null ? '-' : row.canal,
            sortable: true,
        },
        {
            name: 'Descripción',
            selector: row => row.descripcion === null ? '-' : row.descripcion,
            sortable: true,
        },
        {
            name: 'Marca',
            selector: row => row.marca === null ? '-' : row.marca,
            sortable: true,
        },
        {
            name: 'Ticket',
            selector: row => row.ticket === null ? '-' : row.ticket,
            sortable: true,
        },
        {
            name: 'Eliminar',
            cell: (row) => <Button className="btn btn-danger" onClick={() => handleDeleteTicket(row)} id={row.id} ><i className="fa fa-trash"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        
    ]);

    useEffect(() => {
        ticketService.getTicketById(id)
            .then((response) => {
                setTicketId(response.data.id)
                setLoading(false)
                setIsLoading(true)
            }).catch(error => {
                console.log(error)
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        if (loading) {
            ticketService.getProduct()
                .then(response => {
                    setProductList(response.data)
                    console.log(response)
                    setLoading(false)
                    setIsLoading(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [])

    const handleDeleteTicket = async (item) => {
        try {
            const respuesta = confirm("¿Estas seguro de eliminar el registro?")
            if (respuesta === true) {
                const data = await ticketService.deleteTicketProduct(ticketId, item.id)
                if (data.successful)
                    toast.success('Prodcuto eliminado correctamente!');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <div className="container-fluid">
            <ContentHeader title="Lista de productos" /*buttons={headerButtons}*/ />

            {productList && productList.length > 0 ? (

                <DataTable
                    data={productList}
                    customStyles={customStyles}
                    progressPending={loading}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover={true}
                    columns={columns}
                />
            ) : <div>
                <h1 className="text-center">No hay productos</h1>
            </div>}

            <div className="mx-auto text-center mt-5">
                {isLoading ? productList && productList.length > 0 : <Spinner animation="border" />}
            </div>
        </div>
    );
};

export default ProductList;
