const VALUE_TICKETS = 50;

const priceTotal = (products) => {
    let total = 0

    if (!products.length === 0)
        return 0

    products.forEach(item => {
        total += item.price * item.quantity
    })

    return total
}

const quantityTotal = (products) => {
    let total = 0

    if (!products.length === 0)
        return 0

    products.forEach(item => {
        total += parseInt(item.quantity)
    })

    return total
}

const pointsTotal = (products) => {
    let total = 0

    if (!products.length === 0)
        return 0

    products.forEach(item => {
        total += item.price * item.quantity
    })

    total = Math.floor((total * VALUE_TICKETS) / 100)

    return total
}

const pointsTotalOfProduct = (price, quantity = 1) => {
    let total = price * quantity
    total = Math.floor((total * VALUE_TICKETS) / 100)
    return total
}

export default priceTotal
export { quantityTotal, pointsTotal, pointsTotalOfProduct}
