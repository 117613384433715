import React, { useState, useEffect, useMemo } from 'react';
import { ContentHeader, Button, FilterComponent } from '@components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as newService from '../../services/news';
import prettyDate from '../../utils/pretty-date'
import { prettyStatusNews } from '../../utils/string-manipulation'
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

const headerButtons = [
    {
        text: "Agregar nueva noticia",
        urlSlug: "/news/create",
    }
];

const News = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [news, setNews] = useState([])
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{t('general.label.name')}</b>,
            selector: row => row.name === null ? '-' : row.name,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('news.label.author')}</b>,
            selector: row => row.autor === null ? '-' : row.autor,
            sortable: true,
        },
        {
            name: <b>{t('news.label.readingTime')}</b>,
            selector: row => row.readingTime === "" ? '-' : row.readingTime,
            sortable: true,
        },
        {
            name: <b>{t('news.label.category')}</b>,
            selector: row => row.tag.name === null ? '-' : row.tag.name,
            sortable: true,
        },
        {
            name: <b>{t('general.label.status')}</b>,
            selector: row => row.activa,
            cell: (row) => <span> {prettyStatusNews(row.activa)}</span>,
            sortable: true,
        },
        {
            name: <b>{t('general.label.dateOfCreated')}</b>,
            selector: row => row.createdAt,
            sortable: true,
            format: row => prettyDate(row.createdAt),
        },
        {
            name: '',
            cell: (row) => <Button className="m-1 btn btn-danger" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-edit"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ]);

    // Hooks para el filtro de la tabla
    const [filterName, setFilterName] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = news.filter(
        item => (item.name && item.name.toLowerCase().includes(filterName.toLowerCase())) &&
            (item.autor && item.autor.toLowerCase().includes(filterAuthor.toLowerCase())) &&
            (item.tag && item.tag.name.toLowerCase().includes(filterCategory.toLowerCase())),
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterAuthor) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterAuthor('');
            }

            if (filterCategory) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterCategory('');
            }


            if (filterName) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterName('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Nombre' onFilter={e => setFilterName(e.target.value)} filterText={filterName} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Autor' onFilter={e => setFilterAuthor(e.target.value)} filterText={filterAuthor} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Categoría' onFilter={e => setFilterCategory(e.target.value)} filterText={filterCategory} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterAuthor, filterCategory, filterName, resetPaginationToggle]);

    useEffect(() => {
        dispatch(setLoading(true));
        newService.getNews()
            .then(response => {
                setNews(response.data)
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })
    }, [])

    const handleButtonClick = (item) => {
        history.push(`/news/edit/${item.id}`)
    };

    return (
        <>
            <div id="news-list">
                <ContentHeader title={t('menusidebar.label.news')} buttons={headerButtons} />

                {news && news.length > 0 ?
                    <DataTable
                        data={filteredItems}
                        customStyles={customStyles}
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noDataComponent="No se encuentran registros"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                    : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                    </div>}

            </div>
        </>
    );


};

export default News;