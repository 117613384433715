const PRODUCTS = [
    {
        "product": "TH Natural Touch 80´s Ecommerce",
        "sku": "750 64256 21675"
    },
    {
        "product": "TH Humedas Supreme Platino Clubes",
        "sku": "750 64256 36624"
    },
    {
        "product": "TH Huggies Supreme 80s",
        "sku": "750 64256 07808"
    },
    {
        "product": "TH Humedas Supreme Care Clubes",
        "sku": "750 64256 07655"
    },
    {
        "product": "TH Huggies Supreme Duopack 160's",
        "sku": "750 64256 08867"
    },
    {
        "product": "Th Huggies Supreme 80´s",
        "sku": "750 64256 36808"
    },
    {
        "product": "TH Huggies Cuidado Hidratante 80's",
        "sku": "750 19434 54743"
    },
    {
        "product": "Hidratante Mickey V3",
        "sku": "750 19434 94022"
    },
    {
        "product": "TH Huggies Tripack Hidratante 80´s",
        "sku": "750 19434 13054"
    },
    {
        "product": "TH Huggies Duopack Hidratante 80´s más travel pack",
        "sku": "750 64256 33005"
    },
    {
        "product": "TH Huggies Cuidado Puro 80´s",
        "sku": "750 19434 54811"
    },
    {
        "product": "TH Huggies Tripack Puro 80´s",
        "sku": "750 19434 13214"
    },
    {
        "product": "TH Huggies Cuidado Puro 8 pack",
        "sku": "750 64256 46470"
    },
    {
        "product": "TH Huggies Cuidado Humectante 80´s",
        "sku": "750 19434 54873"
    },
    {
        "product": "Cuidado Esencial",
        "sku": "750 19434 54941"
    },
    {
        "product": "TH Huggies Eco Protect 80s",
        "sku": "750 64256 24669"
    },
    {
        "product": "Huggies Supreme Prematuros",
        "sku": "750 19434 42405"
    },
    {
        "product": "Huggies Supreme RN SOFTCARE 40 UNIS",
        "sku": "750 19434 16178"
    },
    {
        "product": "Huggies Supreme E1 SOFTCARE 40 UNIS",
        "sku": "750 19434 16864"
    },
    {
        "product": "Huggies Supreme E2 SOFTCARE 40 UNIS",
        "sku": "750 19434 41705"
    },
    {
        "product": "HUGGIES SUPREME E3 UNISEX 36 UNIS",
        "sku": "750 19434 41729"
    },
    {
        "product": "HUGGIES SUPREME E4 UNISEX 36 UNIS",
        "sku": "750 19434 41767"
    },
    {
        "product": "HUGGIES SUPREME E5 UNISEX 36 UNIS",
        "sku": "750 19434 41804"
    },
    {
        "product": "HUGGIES SUPREME E6 UNISEX 36 UNIS",
        "sku": "750 19434 45796"
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA RN UNISEX 84",
        "sku": 7506425622955
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA E1 UNISEX 84",
        "sku": 7506425622962
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA E2 UNISEX 84",
        "sku": 7506425622979
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA E3 UNISEX 84",
        "sku": 7506425621071
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA UNISEX E4 84",
        "sku": 7506425621101
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA UNISEX E5 84",
        "sku": 7506425621125
    },
    {
        "product": "HUGGIES SUPREME PLATINO STELLA UNISEX E6 84",
        "sku": 7506425621149
    },
    {
        "product": "HUGGIES SUPREME PLATINO E1 UNISEX 120´s",
        "sku": 7506425622986
    },
    {
        "product": "HUGGIES SUPREME PLATINO E2 UNISEX 120´s",
        "sku": 7506425622993
    },
    {
        "product": "HUGGIES SUPREME PLATINO E3 UNISEX 120´s",
        "sku": 7506425621033
    },
    {
        "product": "HUGGIES SUPREME PLATINO E4 UNISEX 120S",
        "sku": 7506425635047
    },
    {
        "product": "HUGGIES SUPREME PLATINO E5 UNISEX 120S",
        "sku": 7506425635054
    },
    {
        "product": "HUGGIES SUPREME PLATINO E6 UNISEX 120S",
        "sku": 7506425635061
    },
    {
        "product": "Huggies Eco Protect RN/E1 UNISEX 40´s",
        "sku": 7506425621422
    },
    {
        "product": "Huggies Eco Protect E2 UNISEX 40´s",
        "sku": 7506425621446
    },
    {
        "product": "Huggies Eco Protect ET3 36’s",
        "sku": 7506425649174
    },
    {
        "product": "Huggies Eco Protect ET4 36’s",
        "sku": 7506425649198
    },
    {
        "product": "Huggies Eco Protect ET5 32’s",
        "sku": 7506425649211
    },
    {
        "product": "Huggies Eco Protect ET6 32’s",
        "sku": 7506425649235
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES RN 120s",
        "sku": 7506425620739
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E1 120s",
        "sku": 7506425620746
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E2 120s",
        "sku": 7506425620753
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E3 100s",
        "sku": 7506425620760
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E4 100s",
        "sku": 7506425620777
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E5 100s",
        "sku": 7506425620784
    },
    {
        "product": "NATURAL TOUCH BY HUGGIES E6 100s",
        "sku": 7506425620791
    },
    {
        "product": "HUC UNISEX RN-E1 40 US",
        "sku": "750 64256 00007"
    },
    {
        "product": "HUC FOREMAN E2 40 US",
        "sku": "750 19434 33090"
    },
    {
        "product": "HUC FOREMAN E3 40 ÑO",
        "sku": "750 64256 04241"
    },
    {
        "product": "HUC FOREMAN E3 40 ÑA",
        "sku": "750 64256 04265"
    },
    {
        "product": "HUC FOREMAN E4 40 ÑO",
        "sku": "750 64256 04289"
    },
    {
        "product": "HUC FOREMAN E4 40 ÑA",
        "sku": "750 64256 04302"
    },
    {
        "product": "HUC FOREMAN E5 40 ÑO",
        "sku": "750 64256 04326"
    },
    {
        "product": "HUC FOREMAN E5 40 ÑA",
        "sku": "750 64256 04340"
    },
    {
        "product": "HUC FOREMAN E6 40 ÑO",
        "sku": "750 64256 04364"
    },
    {
        "product": "HUC FOREMAN E6 40 ÑA",
        "sku": "750 64256 04388"
    },
    {
        "product": "HUC FOREMAN E3 60 ÑO",
        "sku": "750 19434 16642"
    },
    {
        "product": "HUC FOREMAN E3 60 ÑA",
        "sku": "750 19434 16666"
    },
    {
        "product": "HUC FOREMAN E4 60 ÑO",
        "sku": "750 19434 16680"
    },
    {
        "product": "HUC FOREMAN E4 60 ÑA",
        "sku": "750 19434 16703"
    },
    {
        "product": "HUC FOREMAN E5 60 ÑO",
        "sku": "750 19434 16727"
    },
    {
        "product": "HUC FOREMAN E5 60 ÑA",
        "sku": "750 19434 16741"
    },
    {
        "product": "HUC FOREMAN E6 60 ÑO",
        "sku": "750 19434 17120"
    },
    {
        "product": "HUC FOREMAN E6 60 ÑA",
        "sku": "750 19434 17144"
    },
    {
        "product": "HUC FOREMAN E3 90 ÑO",
        "sku": "750 19434 13542"
    },
    {
        "product": "HUC FOREMAN E3 90 ÑA",
        "sku": "750 19434 13559"
    },
    {
        "product": "HUC FOREMAN E4 90 ÑO",
        "sku": "750 19434 13566"
    },
    {
        "product": "HUC FOREMAN E4 90 ÑA",
        "sku": "750 19434 13573"
    },
    {
        "product": "HUC FOREMAN E5 90 ÑO",
        "sku": "750 19434 13603"
    },
    {
        "product": "HUC FOREMAN E5 90 ÑA",
        "sku": "750 19434 13610"
    },
    {
        "product": "HUC FORMEAN E6 90 ÑO",
        "sku": "750 19434 13627"
    },
    {
        "product": "HUC FORMEAN E6 90 ÑA",
        "sku": "750 19434 13634"
    },
    {
        "product": "ALL AROUND E3 40 UNISEX",
        "sku": "750 64256 03671"
    },
    {
        "product": "ALL AROUND E4 40 UNISEX",
        "sku": "750 19434 88649"
    },
    {
        "product": "ALL AROUND E5 40 UNISEX",
        "sku": "750 19434 88663"
    },
    {
        "product": "ALL AROUND E6 40 UNISEX",
        "sku": "750 19434 88687"
    },
    {
        "product": "ALL AROUND E7 40 UNISEX",
        "sku": "750 6425625307"
    },
    {
        "product": "ALL AROUND E3 90 UNISEX",
        "sku": "750 64256 08577"
    },
    {
        "product": "ALL AROUND E4 90 UNISEX",
        "sku": "750 64256 08591"
    },
    {
        "product": "ALL AROUND E5 90 UNISEX",
        "sku": "750 64256 08614"
    },
    {
        "product": "ALL AROUND E6 90 UNISEX",
        "sku": "750 64256 08638"
    },
    {
        "product": "ALL AROUND E7 90 UNISEX",
        "sku": "750 6425625321"
    },
    {
        "product": "CR SUPREME 200ML",
        "sku": "750 19434 84207"
    },
    {
        "product": "JB SUPREME 80G",
        "sku": "750 19434 84238"
    },
    {
        "product": "2 EN 1 SUPREME 200ML",
        "sku": "750 19434 84214"
    },
    {
        "product": "SH SUPREME 200ML",
        "sku": "750 19434 84221"
    },
    {
        "product": "CR CUID RELAJANTE 250ML",
        "sku": "750 19434 89196"
    },
    {
        "product": "SH CUID RELAJANTE 250ML",
        "sku": "750 19434 89219"
    },
    {
        "product": "JL CUID RELAJANTE 250ML",
        "sku": "750 19434 89233"
    },
    {
        "product": "JB CUID RELAJANTE 80G",
        "sku": 7501943450103
    },
    {
        "product": "SH CUID RELAJANTE 400ML",
        "sku": 7501943470552
    },
    {
        "product": "CR CUID RELAJANTE 400ML",
        "sku": 7501943470514
    },
    {
        "product": "OA SH 400ML + JAB 80G",
        "sku": "750 64256 39038"
    },
    {
        "product": "CR MANZANILLA 250 ML",
        "sku": "750 19434 89349"
    },
    {
        "product": "JABON BARRA MANZANILLA",
        "sku": "750 64256 17869"
    },
    {
        "product": "SH MANZANILLA 250 ML",
        "sku": "750 19434 89356"
    },
    {
        "product": "Shampoo Natural Touch 250ml",
        "sku": "750 64256 28025"
    },
    {
        "product": "Crema Natural Touch 250ml",
        "sku": "750 64256 28032"
    },
    {
        "product": "Jabon Natural Touch 80g",
        "sku": "750 64256 28049"
    },
    {
        "product": "Bálsamo Natural Touch 100ml",
        "sku": "750 64256 29350"
    },
    {
        "product": "GoodNites L-XL (Grande)",
        "sku": "00036 0004 56103"
    },
    {
        "product": "GoodNites L-XL (E-commerce)",
        "sku": "00036 0004 56103"
    },
    {
        "product": "GoodNites S-M (Mediano)",
        "sku": "00036 0001 80800"
    },
    {
        "product": "GoodNites L-XL (Grande) Farmacias",
        "sku": "750 64256 15773"
    },
    {
        "product": "Goodnites Niña L/XL 11´s",
        "sku": "00036 0004 13168"
    },
    {
        "product": "Pull Ups 30's Niño M",
        "sku": "750 64256 05804"
    },
    {
        "product": "Pull Ups 30's Niña M",
        "sku": "750 64256 05828"
    },
    {
        "product": "Pull Ups 30's Niño L",
        "sku": "750 64256 05842"
    },
    {
        "product": "Pull Ups 30's Niña L",
        "sku": "750 64256 05866"
    },
    {
        "product": "Pull Ups 30's Niño XL",
        "sku": "750 64256 05880"
    },
    {
        "product": "Pull Ups 30's Niño XL Frozen",
        "sku": "750 64256 05880"
    },
    {
        "product": "Pull Ups 30's Niña XL",
        "sku": "750 64256 05903"
    },
    {
        "product": "Pull Ups 30's Niña XL Frozen",
        "sku": "750 64256 05903"
    },
    {
        "product": "Pull Ups 15's Niño L",
        "sku": "750 64256 05965"
    },
    {
        "product": "Pull Ups 15's Niña L",
        "sku": "750 64256 05989"
    },
    {
        "product": "Pull Ups 15's Niño XL",
        "sku": "750 64256 06009"
    },
    {
        "product": "Pull Ups 15's Niño XL Frozen",
        "sku": "750 64256 06009"
    },
    {
        "product": "Pull Ups 15's Niña XL",
        "sku": "750 64256 06023"
    },
    {
        "product": "Pull Ups 15's Niña XL Frozen",
        "sku": "750 64256 06023"
    },
    {
        "product": "Pull Ups City Club (52/48) E6",
        "sku": "750 19434 88595"
    },
    {
        "product": "Pull Ups niño",
        "sku": "750 19434 93643"
    },
    {
        "product": "Pull Ups niña",
        "sku": "750 19434 93650"
    },
    {
        "product": "Pull Ups XL Costco 90s",
        "sku": "750 6425626236"
    },
    {
        "product": "Pull ups XL Niño 90s",
        "sku": "750 6425626243"
    },
    {
        "product": "Pull ups XL Niña 90s",
        "sku": "750 6425626250"
    },
    {
        "product": "Pull Ups L Costco 90s",
        "sku": 7506425626434
    },
    {
        "product": "Pull Ups L Niño 90s",
        "sku": 7506425626441
    },
    {
        "product": "Pull Ups L Niña 90s",
        "sku": 7506425626458
    },
    {
        "product": "Little Swimmers Pequeño 12's",
        "sku": "036 0001 83399"
    },
    {
        "product": "Little Swimmers Mediano 11's",
        "sku": "036 0001 83429"
    },
    {
        "product": "Little Swimmers Pequeño 3's Exhibidor",
        "sku": "750 19434 18110"
    },
    {
        "product": "Little Swimmers Pequeño 3's Individual",
        "sku": "750 64256 01530"
    },
    {
        "product": "Little Swimmers Mediano 3's Exhibidor",
        "sku": "750 19434 18134"
    },
    {
        "product": "Little Swimmers Mediano 3's Individual",
        "sku": "750 64256 01547"
    }
];

export default PRODUCTS;