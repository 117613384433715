const prettyDate = (a) => {
    var fechaUTC = new Date(a)
    try {

        const year = fechaUTC.getFullYear()
        const month = getPrettyMonth(fechaUTC.getMonth())
        const date = ((fechaUTC.getDate() > 9) ? fechaUTC.getDate() : ('0' + fechaUTC.getDate()))
        const hours = ((fechaUTC.getHours() > 9) ? fechaUTC.getHours() : ('0' + fechaUTC.getHours()))
        const minutes = ((fechaUTC.getMinutes() > 9) ? fechaUTC.getMinutes() : ('0' + fechaUTC.getMinutes()))
        const seconds = fechaUTC.getSeconds()
        return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`
    } catch (error) {
        return fechaUTC
    }
}

const prettyDateOnly = (b) => {
    var fechaUTCHours = new Date(b)
    try {
        const year = fechaUTCHours.getFullYear()
        const month = getPrettyMonth(fechaUTCHours.getMonth())
        const date = ((fechaUTCHours.getDate() > 9) ? fechaUTCHours.getDate() : ('0' + fechaUTCHours.getDate()))
        
        return `${date}/${month}/${year}`
    } catch (error) {
        return fechaUTCHours
    }
}

const prettyOnlyDate = (a) => {
    try {
        const split = a.split('-')

        const year = split[0]
        const month = split[1]
        let date = split[2]
        const dateSplit = date.split('T')
        date = dateSplit[0]
        return `${date}/${month}/${year}`
    } catch (error) {
        return a
    }
}

const prettyOnlyDateOfBirth = (a) => {
    try {
        const split = a.split('-')
        const year = split[0]
        const month = split[1]
        let date = split[2]
        const dateSplit = date.split('T')
        date = dateSplit[0]
        return `${year}-${month}-${date}`

    } catch (error) {
        return a
    }
}

const getPrettyMonth = (monthIndex, language = 'en') => {
    switch (Number(monthIndex)) {
        case 0: return language === 'en' ? '01' : '01'
        case 1: return language === 'en' ? '02' : '02'
        case 2: return language === 'en' ? '03' : '03'
        case 3: return language === 'en' ? '04' : '04'
        case 4: return language === 'en' ? '05' : '05'
        case 5: return language === 'en' ? '06' : '06'
        case 6: return language === 'en' ? '07' : '07'
        case 7: return language === 'en' ? '08' : '08'
        case 8: return language === 'en' ? '09' : '09'
        case 9: return language === 'en' ? '10' : '10'
        case 10: return language === 'en' ? '11' : '11'
        case 11: return language === 'en' ? '12' : '12'
        default: return ''
    }
}

const prettyDateFromSeconds = (dateInSeconds, language = 'en') => {
    let a = new Date(dateInSeconds * 1000)
    let newDate
    try {
        const date = a.getDate()
        const month = a.getMonth()
        const year = a.getFullYear()
        if (date < 10) {
            newDate = '0' + a.getDate()
        } else {
            newDate = a.getDate()
        }
        if (language === 'en')
            return `${newDate}/${getPrettyMonth(month)}/${year}`
    } catch (error) {
        console.log('prettyDateFromSeconds error')
    }
    return '1'
}

const prettyDateFromSeconds2 = (dateInSeconds, days, language = 'en') => {
    let a = new Date(dateInSeconds * 1000)
    a.setDate(a.getDate() + days);
    try {
        const date = a.getDate()
        const month = a.getMonth()
        const year = a.getFullYear()
        if (language === 'en')
            return `${date}/${getPrettyMonth(month)}/${year}`
    } catch (error) {
        console.log('prettyDateFromSeconds error')
    }
    return '1'
}

export default prettyDate
export { prettyOnlyDate}
export { prettyOnlyDateOfBirth}
export { prettyDateFromSeconds }
export { prettyDateFromSeconds2 }
export { prettyDateOnly }