import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea, Checkbox} from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import { setLoading } from '@app/store/reducers/ui';
import * as Yup from 'yup';
import * as promotionsService from '../../services/promotions';


const EditPromotion = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const [isActive, setIsActive] = useState(false);
    const types = [
        {
            id: 0,
            name: "Por Código",
        },
        {
            id: 1,
            name: "Por Ticket",
        }
    ]

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            description: '',
            code: '',
            pointValue: 0,
            priceTicket: 0.0,
            status: 0,
            promotionType: 0
        },
        validationSchema: Yup.object({
            startDate: Yup.date(),
            endDate: Yup.date().min(
                Yup.ref('startDate'),
                "La fecha final no puede ser antes de la inicial"
            ),
            description: Yup.string()
                .required(t('validationSchema.detailsNewsRequired')),
            code: Yup.string()
                .required(t('validationSchema.infoNewRequired')),
            pointValue: Yup.number()
                .when(['promotionType'], (promotionType) => {
                    if (promotionType === 0)
                        return Yup.number()
                            .required(t('validationSchema.onlyNumberWithotDecimal'))
                }),
            priceTicket: Yup.number()
                .when(['promotionType'], (promotionType) => {
                    if (promotionType === 1)
                        return Yup.number()
                            .required(t('validationSchema.onlyNumberWithotDecimal'))
                })
        }),
        onSubmit: (data) => {
            sendPromotion(data);
        }
    });

    const sendPromotion = async (data) => {

        if (isActive) {
            data.status = 0;
        } else {
            data.status = 1;
        }
        const promotion =
        {
            startDate: data.startDate,
            endDate: data.endDate,
            description: data.description,
            code: data.code,
            points: data.promotionType === 0 ? data.pointValue : 0,
            price: data.promotionType === 1 ? data.priceTicket : 0,
            status: data.status,
            promotionType: data.promotionType
        }
        try {
            await promotionsService.updatePromotions(id, promotion)

        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() - 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const handleDelete = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await promotionsService.deletePromotions(id);
                toast.success(t('promotions.buttons.deleteSuccess'));
                history.push('/promotions');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleType = (item) => {
        const newData = formik.values;
        newData.promotionType = item.id;
        formik.setValues({ ...newData }, true);
    }

    useEffect(() => {
        dispatch(setLoading(true));
        promotionsService.getPromotionsById(id)
            .then(response => {
                formik.setValues({
                    startDate: response.data.startDate,
                    endDate: response.data.endDate,
                    description: response.data.description,
                    code: response.data.code,
                    pointValue: response.data.pointValue,
                    priceTicket: response.data.priceTicket,
                    status: response.data.status,
                    promotionType: response.data.promotionType
                }, true)
                if (formik.values.status === 0) {
                    setIsActive(true);
                }
                dispatch(setLoading(false));
            })
            .catch(err => {
                console.log(err)
                dispatch(setLoading(false));
            })
    }, []);

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('promotions.edit')}</h3>
                        <div className="mr-1 card-tools">
                            <Button
                                type="submit" block
                                onClick={handleDelete}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-12 col-form-label"
                                        >
                                            {t('promotions.duration')}
                                        </label>
                                        <div className="col-sm-6">
                                            <Input
                                                value={prettyOnlyDateOfBirth(formik.values.startDate) || ''}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('startDate')}
                                                type="date"
                                                min={disablePastDate()}
                                                placeholder={t('promotions.startDate')} />
                                        </div>

                                        <div className="col-sm-6">
                                            <Input
                                                value={prettyOnlyDateOfBirth(formik.values.endDate) || ''}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('endDate')}
                                                type="date"
                                                min={disablePastDate()}
                                                placeholder={t('promotions.endDate')} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.description')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('promotions.description')}
                                                type="text"
                                                value={formik.values.description}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('description')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.code')}
                                        </label>
                                        <div className="col-sm-4">
                                            <Input
                                                placeholder={t('promotions.code')}
                                                type="text"
                                                value={formik.values.code}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('code')}
                                            />
                                        </div>

                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.type')}
                                        </label>
                                        <div className="col-sm-4">
                                            <ComboBox
                                                items={types}
                                                optionTitle="name"
                                                messageOptionDefault="Seleccione un tipo"
                                                value={formik.values.promotionType}
                                                formikFieldProps={formik.getFieldProps('promotionType')}
                                                formik={formik}
                                                onChange={handleType}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        {
                                            formik.values.promotionType == types[1].id ? (
                                                <>
                                                    <label
                                                        htmlFor="inputName"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        {t('promotions.price')}
                                                    </label>
                                                    <div className="col-sm-4">
                                                        <Input
                                                            placeholder={t('promotions.price')}
                                                            type="number"
                                                            value={formik.values.priceTicket}
                                                            formik={formik}
                                                            formikFieldProps={formik.getFieldProps('priceTicket')}
                                                        />
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <label
                                                        htmlFor="inputName"
                                                        className="col-sm-2 col-form-label"
                                                    >
                                                        {t('promotions.point')}
                                                    </label>
                                                    <div className="col-sm-4">
                                                        <Input
                                                            placeholder={t('promotions.point')}
                                                            type="number"
                                                            formik={formik}
                                                            value={formik.values.pointValue}
                                                            formikFieldProps={formik.getFieldProps('pointValue')}
                                                        />
                                                    </div>
                                                </>
                                            )
                                        }
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('general.label.status')}
                                        </label>
                                        <div className="col-sm-2">
                                            <Checkbox
                                                label="Activa"
                                                checked={isActive}
                                                onChange={value => setIsActive(value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/promotions`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('promotions.buttons.create')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPromotion