import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, ComboBox, Textarea } from '@components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { useTranslation } from 'react-i18next';
import { prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import * as Yup from 'yup';
import * as PromotionsService from '../../services/promotions';


const CreatePromotion = () => {
    const [t] = useTranslation();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const dispatch = useDispatch();
    const types = [
        {
            id: 0,
            name: "Por Código",
        },
        {
            id: 1,
            name: "Por Ticket",
        }
    ];

    const formik = useFormik({
        initialValues: {
            startDate: '',
            endDate: '',
            description: '',
            code: '',
            points: 0,
            price: 0.0,
            promotionType: 0
        },
        validationSchema: Yup.object({
            startDate: Yup.date(),
            endDate: Yup.date().min(
                Yup.ref('startDate'),
                "La fecha final no puede ser antes de la inicial"
            ),
            description: Yup.string()
                .required(t('validationSchema.detailsNewsRequired')),
            code: Yup.string()
                .required(t('validationSchema.infoNewRequired')),
            points: Yup.number()
                .when(['promotionType'], (promotionType) => {
                    if (promotionType === 0)
                        return Yup.number()
                            .required(t('validationSchema.onlyNumberWithotDecimal'))
                }),
            price: Yup.number()
                .when(['promotionType'], (promotionType) => {
                    if (promotionType === 1)
                        return Yup.number()
                            .required(t('validationSchema.onlyNumberWithotDecimal'))
                })
        }),
        onSubmit: (data) => {
            sendPromotion(data);
        }
    });

    const sendPromotion = async (data) => {
        try {
            const promotion =
            {
                startDate: data.startDate,
                endDate: data.endDate,
                description: data.description,
                code: data.code,
                points: data.promotionType === 0 ? data.points : 0,
                price: data.promotionType ===  1 ? data.price : 0,
                promotionType: data.promotionType
            }
            setSaveLoading(true);
            await PromotionsService.createPromotions(promotion);
            toast.success(t('promotions.buttons.createSuccess'));
            dispatch(setLoading(false));
            history.push('/promotions');
        } catch (error) {
            setSaveLoading(false);
            dispatch(setLoading(false));
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }
    }

    const disablePastDate = () => {
        const today = new Date();
        const dd = String(today.getDate() + 1).padStart(2, "0");
        const mm = String(today.getMonth() - 1).padStart(2, "0");
        const yyyy = today.getFullYear();
        return yyyy + "-" + mm + "-" + dd;
    };

    const handleType = (item) => {
        const newData = formik.values;
        newData.promotionType = item.id;
        formik.setValues({ ...newData }, true);
        console.log(formik.values)
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('promotions.create')}</h3>
                    </div>
                    <div className="card-body">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-12 col-form-label"
                                        >
                                            {t('promotions.duration')}
                                        </label>
                                        <div className="col-sm-6">
                                            <Input
                                                value={prettyOnlyDateOfBirth(formik.values.startDate) || ''}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('startDate')}
                                                type="date"
                                                min={disablePastDate()}
                                                placeholder={t('promotions.startDate')} />
                                        </div>

                                        <div className="col-sm-6">
                                            <Input
                                                value={prettyOnlyDateOfBirth(formik.values.endDate) || ''}
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('endDate')}
                                                type="date"
                                                min={disablePastDate()}
                                                placeholder={t('promotions.endDate')} />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.description')}
                                        </label>
                                        <div className="col-sm-10">
                                            <Textarea
                                                placeholder={t('promotions.description')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('description')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.code')}
                                        </label>
                                        <div className="col-sm-4">
                                            <Input
                                                placeholder={t('promotions.code')}
                                                type="text"
                                                formik={formik}
                                                formikFieldProps={formik.getFieldProps('code')}
                                            />
                                        </div>

                                        <label
                                            htmlFor="inputName"
                                            className="col-sm-2 col-form-label"
                                        >
                                            {t('promotions.type')}
                                        </label>
                                        <div className="col-sm-4">
                                            <ComboBox
                                                items={types}
                                                optionTitle="name"
                                                messageOptionDefault="Seleccione un tipo"
                                                value={formik.values.promotionType}
                                                formikFieldProps={formik.getFieldProps('promotionType')}
                                                formik={formik}
                                                onChange={handleType}
                                            />
                                        </div>
                                    </div>
                                    {
                                        formik.values.promotionType == types[1].id ? (
                                            <div className="form-group row">
                                                <label
                                                    htmlFor="inputName"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    {t('promotions.price')}
                                                </label>
                                                <div className="col-sm-4">
                                                    <Input
                                                        placeholder={t('promotions.price')}
                                                        type="number"
                                                        value={formik.values.price}
                                                        formik={formik}
                                                        formikFieldProps={formik.getFieldProps('price')}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                                <div className="form-group row">
                                                <label
                                                    htmlFor="inputName"
                                                    className="col-sm-2 col-form-label"
                                                >
                                                    {t('promotions.point')}
                                                </label>
                                                <div className="col-sm-4">
                                                    <Input
                                                        placeholder={t('promotions.point')}
                                                        type="number"
                                                        formik={formik}
                                                        value={formik.values.points}
                                                        formikFieldProps={formik.getFieldProps('points')}
                                                    />
                                                    </div>
                                                </div>
                                            )
                                        } 
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/promotions`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button type="submit" block
                                        isLoading={isSaveLoading} className="btn btn-danger">
                                        {t('promotions.buttons.create')}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePromotion