import Api from '../utils/axios';
const api = new Api();

export const loginByAuth = async (data) => {
    const user = await api.login(data);
    //console.log(user)
    const token = user.data.token.token
    //console.log(token)
    const dataUser = user.data
    localStorage.setItem('datauser', JSON.stringify(dataUser))
    localStorage.setItem('token', token);
    //console.log(localStorage.getItem('datauser'))
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token; 
};

export const registerByAuth = async (email, password) => {
    // eslint-disable-next-line no-console
    const token = 'test';
    console.log()
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByGoogle = async () => {
    const token = 'test';
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByGoogle = async () => {
    const token = 'test';
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const loginByFacebook = async () => {
    const token = 'test';
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};

export const registerByFacebook = async () => {
    const token = 'test';
    localStorage.setItem('token', token);
    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('hold-transition');
    return token;
};
