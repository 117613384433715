import React, { useState, useEffect } from 'react';
import { Button, Input, Textarea, ImageUpload} from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useHistory, Link } from 'react-router-dom';
import * as usersService from '../../services/notifications';
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';

const CreateNotification = () => {
    const api = new Api();
    const history = useHistory();
    const [isSaveLoading, setSaveLoading] = useState(false);
    const [uploadImage, setUploadImage] = useState();
    const [t] = useTranslation();
    const [send, setSend] = useState(true);
    const [valueClientId, setValueClientId] = useState();
    const [sendAll, setSendAll] = useState(false);
    const dispatch = useDispatch();

    const [sendNotification, setSendNotification] = useState(2)

    const formik = useFormik({
        initialValues: {
            title: '',
            message: '',
            sendUser: true,
            fileId: null,
            clientId: "",
        },
        validationSchema: Yup.object({
            title: Yup.string()
                .max(40, t('validationSchema.max40Caracters') )
                .required(t('validationSchema.titleRequred')),
            message: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .max(1000, t('validationSchema.man1000Caracters'))
                .required(t('validationSchema.messageRequired')),
            clientId: Yup.string()
                .max(150, t('validationSchema.max150Caracters'))
                .when(['sendUser'], (sendUser) => {
                    if (sendUser) {
                        return Yup.string()
                            .required(t('validationSchema.UIDRequired'))
                    }
                }),
        }),
        onSubmit: (values) => {
            sendDataDonation(values);
        }
    });

    const uploadImageRequest = async (file) => {
        let formData = new FormData();
        formData.append("file", file)
        try {
            dispatch(setLoading(true));
            const response = await api.uploadFile(formData)
            setUploadImage(response.data.file)
            dispatch(setLoading(false));
        } catch (error) {
            console.log(error)
            dispatch(setLoading(false));
        }
    }

    const sendDataDonation = async (data) => {
        if (uploadImage != null) {
            data.fileId = uploadImage.id;
        } else {
            data.fileId = null;
        }

        if (send && data.clientId.length === 0) {
            toast.error(t('notifications.label.UIDRequired') );
            return;
        }

        try {
            dispatch(setLoading(true));
            const response = await usersService.createNotifications(data);
            toast.success(t('notifications.label.notificationCreated') );
            dispatch(setLoading(false));

            history.push('/notifications');

            setSaveLoading(true);
            setSaveLoading(false);
        } catch (error) {
            dispatch(setLoading(false));
            setSaveLoading(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleOnChange = (value) => {
        const checkSend = document.querySelector(".send")
        const checkSendAll = document.querySelector(".sendAll")
        const inputClient = document.querySelector("#inputClient")

        if (checkSend.checked == true) {
            sendNotification == 1
            const notificationValueSend = true
            formik.setFieldValue('sendUser', notificationValueSend);
            setSend(notificationValueSend)
            inputClient.disabled = false
        } else if (checkSend.checked == false) {
            send == false
            sendNotification == 0
            inputClient.disabled = true
        }

        if (checkSendAll.checked == true) {
            sendNotification == 2
            const notificationValueSendAll = true
            setSendAll(notificationValueSendAll)
            setSend(!notificationValueSendAll)
            formik.setFieldValue('sendUser', !notificationValueSendAll);
            inputClient.disabled = true
        } else if (checkSendAll.checked == false) {
            sendNotification == 0
            inputClient.disabled = false
        }
    };

    return (
        <div>
            <div className="container-fluid">
                <div className="card ">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('notifications.label.createNotification') }</h3>
                    </div>
                    <div className="p-3">
                        <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label>{t('notifications.label.titleNotification') }</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('title')}
                                            type="text"
                                            placeholder={t('notifications.label.titleNotification')}
                                            value={formik.values.title}
                                        />
                                    </div>
                                    <div className="form-group row">
                                        <label>{t('notifications.label.message')}</label>
                                        <Textarea
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('message')}
                                            type="text"
                                            placeholder={t('notifications.label.message')}
                                            value={formik.values.message} />
                                    </div>

                                    <div className="form-row ml-2">
                                        <div className="form-group col-md-7  form-check">
                                            <input
                                                defaultChecked
                                                name="exampleRadios"
                                                className="form-check-input send"
                                                type="radio"
                                                onChange={(e) => handleOnChange(e.target.checked)}
                                                value={send}
                                            />
                                            <label>{t('notifications.label.sendClient')}</label>
                                        </div>
                                        <div className="form-group  col-md-5 ">
                                            <Input
                                                disabled
                                                id="inputClient"
                                                formik={formik}
                                                onChange={(event) => setValueClientId(event)}
                                                formikFieldProps={formik.getFieldProps('clientId')}
                                                type="text"
                                                placeholder={t('notifications.label.UIDClient')} />
                                        </div>
                                    </div>

                                    <div className="form-group row form-check">
                                        <input
                                            name="exampleRadios"
                                            className="form-check-input sendAll"
                                            type="radio"
                                            onChange={(e) => handleOnChange(e.target.checked)}
                                            value={sendAll}
                                        />
                                        <label>{t('notifications.label.seendAll')}</label>
                                    </div>

                                </div>
                                <div className="col-sm-4">
                                    <ImageUpload
                                        id="myImage"
                                        label={t('notifications.label.imageNotification')}
                                        image={uploadImage}
                                        witdh={515}
                                        height={515}
                                        uploadImage={uploadImageRequest}
                                    />
                                </div>

                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/notifications`}
                                        className="btn text-danger" > {t('notifications.label.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        className="w-10"
                                        isLoading={isSaveLoading}
                                        type="submit" block
                                        className="btn btn-danger" > {t('notifications.label.seendNotification')} </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNotification;
