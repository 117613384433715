import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterComponent, ContentHeader, Button } from '@components';
import { Link, useParams, useHistory } from 'react-router-dom';
import * as usersService from '../../services/tickets';
import Spinner from 'react-bootstrap/Spinner'
import { customStylesTickets, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import prettyDate from '../../utils/pretty-date'
import { prettyOnlyDate } from '../../utils/pretty-date'

const TicketScan = () => {
    const [t] = useTranslation();
    const [ticketScan, setTicketScan] = useState([])
    const [loading, setLoading] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();

    const columns = useMemo(() => [
        {
            name: <b>Id</b>,
            selector: row => row.id,
            sortable: true,
        },
        {
            name: <b>{ t('general.label.status')}</b>,
            selector: row => row.status === 0 ? 'Aceptada' : null || row.status === 1 ? 'En proceso' : null || row.status === 2 ? 'Invalido' : null || row.status === 3 ? 'Cancelado' : null,
            sortable: true,
        },
        {
            name: <b>GUID</b>,
            selector: row => row.client.uid,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('tickets.label.email')}</b>,
            selector: row => row.client.email,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('tickets.label.shopName')}</b>,
            selector: row => row.cadena === null ? '-' : row.cadena.nombre,
            sortable: true,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                },
            },
        },
        {
            name: <b>{t('tickets.label.dateOfPurchase')}</b>,
            selector: row => row.dateOfPurchase === null ? '-' : prettyOnlyDate(row.dateOfPurchase),
            sortable: true,
        },
        {
            name: <b>Código</b>,
            selector: row => row.promotion === null ? '-' : row.promotion.code,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.barCode')}</b>,
            selector: row => row.barcode === null ? '-' : row.barcode,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.addresShop')}</b>,
            selector: row => row.address === null ? '-' : row.address,
            sortable: true,
        },
        {
            name: <b>{t('tickets.label.validate')}</b>,
            cell: (row) => <Button className="btn btn-danger btnEdit" onClick={() => handleButtonClick(row)} id={row.id} ><i className="icon-button fa fa-check"></i></Button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
        {
            name: <b>{t('general.label.dateOfCreated')}</b>,
            selector: row => prettyDate(row.createdAt),
            sortable: true,
        },
    ]);

    // Hooks para el filtro de la tabla
    const [filterNameStore, setFilterNameStore] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterEmail, setFilterEmail] = useState('');
    const [filterUid, setFilterUid] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = ticketScan.filter(
        item => {
            let statusValueFilter = -1;
            switch (filterStatus) {
                case 'En proceso':
                    statusValueFilter = 1;
                    break;
                case 'Invalido':
                    statusValueFilter = 2;
                    break;
                default:
                    return ((item.cadena.nombre && item.cadena.nombre.toLowerCase().includes(filterNameStore.toLowerCase()))
                        && (item.client.uid && item.client.uid.toLowerCase().includes(filterUid.toLowerCase()))
                        && (item.client.email && item.client.email.toLowerCase().includes(filterEmail.toLowerCase())))
            }
            return ((item.cadena.nombre && item.cadena.nombre.toLowerCase().includes(filterNameStore.toLowerCase()))
                && (item.status && item.status == statusValueFilter)
                && (item.client.uid && item.client.uid.toLowerCase().includes(filterUid.toLowerCase()))
                && (item.client.email && item.client.email.toLowerCase().includes(filterEmail.toLowerCase())) )
        }
            
    );

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterStatus) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterStatus('');
            }


            if (filterNameStore) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterNameStore('');
            }

            if (filterEmail) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterEmail('');
            }

            if (filterUid) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterUid('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por GUID' onFilter={e => setFilterUid(e.target.value)} filterText={filterUid} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Email' onFilter={e => setFilterEmail(e.target.value)} filterText={filterEmail} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Tienda' onFilter={e => setFilterNameStore(e.target.value)} filterText={filterNameStore} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Estatus' onFilter={e => setFilterStatus(e.target.value)} filterText={filterStatus} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterEmail, filterUid, filterNameStore, filterStatus, resetPaginationToggle]);

    useEffect(() => {
        if (loading) {
            usersService.getTicketsListNotValid()
                .then(response => {
                    setTicketScan(response.data)
                    setLoading(false)
                    setIsLoading(true)
                })
                .catch(err => {
                    console.log(err)
                    setLoading(false)
                })
        }
    }, [])

    

    const handleButtonClick = (item) => {
            history.push(`/ticketScan/edit/${item.id}`)
    };

    return (
        <div className="container-fluid">
            <ContentHeader title={t('menusidebar.label.ticketScan')} /*buttons={headerButtons}*/ />

            {ticketScan && ticketScan.length > 0 ? (

                <DataTable
                    data={filteredItems}
                    customStyles={customStylesTickets}
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover={true}
                    columns={columns}
                />
            ) : <div>
                    <h1 className="text-center">{t('general.label.noRegisters')}</h1>
            </div>}

            <div className="mx-auto text-center mt-5">
                {isLoading ? ticketScan && ticketScan.length > 0 : <Spinner animation="border" />}
            </div>
        </div>
    );
};

export default TicketScan;
