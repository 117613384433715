import Api from '../utils/axios';
const api = new Api();

export const getNews = async () => {
    const news = await api.getNews()
    return news;
}

export const createNews = async (data) => {
    const CreatedNew = await api.createNews(data);
    return CreatedNew;
}

export const deleteNews = async (id) => {
    const deleteNew = await api.deleteNews(id);
    return deleteNew.data;
}

export const updateNews = async (id, data) => {
    const updateNew = await api.updateNews(id, data);
    return updateNew;
}

export const getNewsById = async (id) => {
    const foundNew = await api.getNewsById(id);
    return foundNew
}