import React from 'react';
import resolve from '../../utils/objects-manipulations';

const ComboBox = ({
    items,
    onChange,
    optionTitle,
    formik,
    formikFieldProps,
    value,
    hiddenOptionDefault = true,
    messageOptionDefault = 'Seleccione una opción'
}) => {
    const printFormError = () => {
        if (
            (formik.touched[formikFieldProps.name] &&
                formik.errors[formikFieldProps.name]) ||
            (resolve(formikFieldProps.name, formik.touched) &&
                resolve(formikFieldProps.name, formik.errors))
        ) {
            return (
                <div
                    className="invalid-feedback"
                    style={{display: 'inline-block'}}
                >
                    {formik.errors[formikFieldProps.name]
                        ? formik.errors[formikFieldProps.name]
                        : resolve(formikFieldProps.name, formik.errors)}
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <select
                className="form-control"
                onChange={(e) =>
                    onChange(
                        items.find(
                            (element) => element.id == Number(e.target.value)
                        )
                    )
                }
                value={value}
                autoComplete="on"
            >
                <option value={null} hidden={hiddenOptionDefault}>
                    {messageOptionDefault}
                </option>
                {items && items.length > 0
                    ? items.map((item, i) => (
                          <option key={`category-${i}`} value={item.id}>
                              {item[optionTitle]}
                          </option>
                      ))
                    : null}
            </select>
            {formik && formikFieldProps ? printFormError() : null}
        </>
    );
};

export default ComboBox;
