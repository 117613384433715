import React from 'react';

const FilterComponent = ({
    filterText,
    onFilter,
    onClear,
    placeholder }) => (
    <input
        className='form-control'
        id="search"
        type="text"
        placeholder={placeholder}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
    />
);

export default FilterComponent;
