import Api from '../utils/axios';
const api = new Api();

export const getState = async () => {
    const resp = await api.getState()
    return resp;
}

export const getStateById = async (id) => {
    const resp = await api.getStateById(id);
    return resp;
}