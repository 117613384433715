import Api from '../utils/axios';
const api = new Api();

export const getAppData = async () => {
    const data = await api.getAppData()
    return data;
};

export const updateAppData = async (data) => {
    const dataUpdated = await api.updateAppData(data)
    return dataUpdated;
};

export const updateAccumulation = async (accumulation) => {
    const dataUpdated = await api.updateAccumulation(accumulation)
    return dataUpdated;
};