import React from 'react';
import PRODUCTS from './participating-products';

const TermsAndConditions = () => {
    return (
        <div id="terms-root">
            <h1><strong>Términos y Condiciones Huggies® Benefits</strong></h1>
            <p>
                Para participar en la presente actividad de recompensas denominada “Huggies® Benefits” (en lo sucesivo el “plan de lealtad”), se deberá dar lectura íntegra de las siguientes bases y cumplir totalmente con los requisitos y condiciones aquí establecidos, lo cual implicará la comprensión y aceptación de las mismas (en adelante las “bases”), así como del aviso de privacidad para el tratamiento de datos personales.
            </p>
            <p>
                Cobertura geográfica: El presente plan de lealtad será válido en los Estados Unidos Mexicanos (en lo sucesivo el “Territorio” y/o “República Mexicana”).
                Para efectos de estas bases se entenderá por:
            </p>
            <p>
                Teléfono inteligente o “Smartphone”: Equipo o aparato portátil de telefonía móvil, con funciones o características que le permiten su conexión a internet y desplegar información dentro o fuera de aplicaciones móviles o también denominadas “app’s”, sujetándose a las políticas de uso y aviso de privacidad al momento de ingreso y uso de la misma.
            </p>
            <p>
                “APP” o aplicación móvil. “Software” o conjunto de programas, instrucciones y reglas informáticas diseñadas para
                ejecutar ciertas tareas en un teléfono inteligente y que permite a los participantes del Plan de Lealtad la conexión
                y desarrollo de la mecánica del mismo, en comunicación con una base de datos.
            </p>
            <p>
                Landing Page o Sitio WEB. La dirección electrónica y su contenido, siendo <a href="www.huggiesbenefits.com">www.huggiesbenefits.com</a>.
            </p>
            <p>
                La Cuenta. Al Registro del Participante que lo identificará y distinguirá de los demás participantes del Plan de Lealtad.
            </p>
            <p>
                Registro de Participante. Se integra de los datos personales proporcionados voluntariamente por el Participante dentro de la APP y que
                componen la Cuenta. Este registro se integra por: dirección de correo electrónico, teléfono de contacto y una contraseña de acceso; pudiendo
                completar su perfil cuando el usuario así lo desee con: nombre, apellido paterno, apellido materno, fecha de nacimiento, lugar de residencia
                (estado, C.P., colonia, calle, número exterior y número interior) y género , y de manera opcional, los nombres, nicknames o alias y fechas de
                nacimiento de sus hijos; concediendo el uso de dichos datos para efectos del presente Plan de Lealtad.

            </p>
            <p>
                Vigencia. Periodo de tiempo durante el cual se desarrolla la Mecánica del Plan de Lealtad y la Dinámica que la compone, conforme a las fechas
                descritas en el capítulo 3 denominado VIGENCIA de estas Bases, así como en la descripción de dicha Dinámica.

            </p>
            <p>
                Participante. Persona física con 18 años cumplidos o mayor, de cualquier nacionalidad; que cumpla con los requisitos establecidos en las Bases;
                no incurra en algún supuesto de descalificación; ha descargado la APP en su Smartphone e ingresado a la misma, proporcionando voluntaria, verídica
                y de forma correcta la información requerida y por lo tanto cuente con el Registro de Participante que le permite accesar a la Cuenta; así como que ha
                cumplido con las condiciones que exige la Dinámica.

            </p>
            <p>
                Mecánica del Plan de Lealtad. Conjunto de lineamientos para participar y aspirar a obtener en su caso, el derecho de obtener, canjear y reclamar alguno
                de los incentivos ofrecidos.

            </p>
            <p>
                Dinámica. Será el procedimiento específico que integra la Mecánica del Plan de Lealtad, el cual se debe desarrollar, participar y cumplir para poder
                aspirar a los Incentivos ofrecidos.
            </p>
            <p>
                Acreedor al Incentivo. Es el Participante que, habiendo ingresado un ticket válido o código de digitalización registrado en su cuenta, cumpliendo con la Dinámica
                de la Mecánica del Plan de Lealtad y conforme a su puntaje obtenido, genera el derecho a canjear, reclamar y recibir el Incentivo ofrecido.
            </p>
            <p>
                Puntos: Son las unidades que para efectos del presente Plan de Lealtad, se entregarán por cada compra realizada, a razón del 5% del valor total de compra
                de productos participantes y mostrados en el ticket, que podrán ser denominados “Hugs”. Cabe destacar que solamente serán tomados en cuenta para la
                respectiva acumulación de Hugs, aquellos productos participantes amparados en el ticket de compra que aparezcan efectivamente pagados y con un valor
                positivo superior a $20.00 (veinte pesos 00/100 M.N.), es decir no podrán acumularse hugs respecto de productos participantes reembolsados o descontados
                del ticket de compra con motivo de promociones u ofertas del establecimiento, debido a que no son acumulables dichas promociones u ofertas para efectos
                del presente Plan de Lealtad

            </p>
            <p>
                Se tendrá como límite máximo de acumulación de “Hugs” vía registro de tickets, la cantidad de 22,700 hugs por participante durante la vigencia de la presente actividad
                y en su caso sus prórrogas, contado a partir del registro del participante; no obstante ello, se podrán seguir acumulando “Hugs” de la manera que más adelante se precisa.
                Los términos referidos en este apartado tendrán igual significado en el uso de plurales o singulares en estas Bases.

            </p>
            <p>
                Establecimientos participantes: <br />
                Los productos participantes en el presente Plan de Lealtad podrán ser adquiridos en cualquier establecimiento de la República Mexicana en los que se comercialicen, siempre
                y cuando emitan ticket de compra impreso por la caja registradora con trazabilidad fiscal, es decir que esté sujeto de ser facturado, y que contenga por lo menos la siguiente
                información:

            </p>
            <ul>
                <li>
                    Nombre de la tienda donde compró los productos participantes.
                </li>
                <li>Ciudad y Estado en la que se ubica la tienda (dentro de la cobertura geográfica del Plan de Lealtad).</li>
                <li>RFC.</li>
                <li>Folio / Número de ticket.</li>
                <li>Fecha y hora de compra.</li>
                <li>Marca de productos Huggies® participantes adquiridos.</li>
                <li>Monto de compra de productos participantes.</li>
            </ul>
            <p>
                La organizadora del Plan de Lealtad se reserva el derecho de autentificar los tickets físicamente y a través de las cadenas emisoras de estos, conforme a la mecánica del
                Plan de Lealtad.
            </p>
            <p>
                Lista de productos participantes: <br />
                Participarán en el presente Plan de Lealtad los productos Huggies® que a continuación se mencionan:

            </p>
            <h5>PRODUCTOS HUGGIES® PARTICIPANTES:</h5>
            <p>
                Los productos participantes que a continuación se mencionan están identificados por código de barras; sin embargo, es necesario aclarar que estos participarán en cualquiera
                de sus presentaciones comercializadas en los establecimientos antes referidos.
            </p>
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th scope="col">Producto</th>
                        <th scope="col">SKU / Código de barras</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        PRODUCTS.map((product, index) => {
                            return (
                                <tr key={index}>
                                    <td>{product.product}</td>
                                    <td>{product.sku}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <h5 className="font-weight-bold">1. ORGANIZADOR DEL PLAN DE LEALTAD:</h5>
            <p>
                Grupo Tramando será la “Organizadora” del Plan de Lealtad, con domicilio en Boulevard de las Ciencias. No. 3015 – 301A. Juriquilla Santa Fe. C.P. 76230. Querétaro, México.
                En caso de dudas, aclaraciones, información y/o reclamos en el canje o redención de puntos dentro del presente Plan de Lealtad, comunicarse al teléfonos (55) 4624 2349 y
                WhatsApp® +52 1 55 7851 5985, en un horario de atención de las 09:00 a las 18:00 horas de lunes a sábado.

            </p>
            <h5 className="font-weight-bold">2. PARTICIPANTES:</h5>
            <p>
                Pueden participar en el presente Plan de Lealtad, las personas físicas que cumplan con los siguientes requisitos:
            </p>
            <ul>
                <li>
                    Ser persona física mayor de edad con plena capacidad de ejercicio, de cualquier nacionalidad.
                </li>
                <li>Contar con identificación oficial vigente (credencial para votar, pasaporte, cartilla del servicio militar liberada o cedula profesional) y en caso de extranjeros,
                    contar con documento que acredite su legal estancia en la República Mexicana, expedido por autoridad competente.</li>
                <li>
                    Dar lectura total y aceptar las presentes Bases, Aviso de privacidad, así como condiciones y términos de uso de la APP y en su caso, del Sitio WEB.

                </li>
                <li>
                    Ingresar a la APP y en su caso, al Sitio WEB, a fin de conocer la sección de Aviso de Privacidad que comprende el tratamiento de los datos que proporcionen los
                    Participantes, así como para realizar su registro y completar la Mecánica del Plan de Lealtad.

                </li>
                <li>
                    Proporcionar datos verídicos (nombre completo y dirección de correo electrónico) conforme le sean requeridos en el Sitio WEB para seguir las instrucciones ahí
                    establecidas; o dirección de correo electrónico, teléfono de contacto y una contraseña de acceso; pudiendo completar su perfil cuando el usuario así lo desee con:
                    nombre, apellido paterno, apellido materno, fecha de nacimiento, lugar de residencia (estado, C.P., colonia, calle, número exterior y número interior), y de manera
                    opcional, los nombres, nicknames o alias y fechas de nacimiento de sus hijos; concediendo el uso de dichos datos para efectos del presente Plan de Lealtad.

                </li>
                <li>
                    Los correos electrónicos proporcionados por los Participantes quedarán en una base de datos, siendo utilizados por la Organizadora para enviarles cualquier información
                    relacionada con el presente Plan de Lealtad, así como los incentivos que soliciten en caso de tener derecho a estos y haberlos solicitado conforme a las presentes bases.
                </li>
                <li>
                    Aceptar que la Cuenta de cada Participante estará integrada de un Registro de Participante y una clave de acceso, la cual podrá ser modificada por el Participante bajo
                    su propio riesgo, por lo que la custodia y en su caso pérdida de esos datos es responsabilidad de este.

                </li>
                <li>
                    Aceptar que las Cuentas serán únicas y se generará una por cada Participante, por lo que en el supuesto que un mismo Participante cree distintas Cuentas, prevalecerá
                    la primera generada y no podrán ser fusionados los puntos en su beneficio.

                </li>
                <li>
                    Aceptar que el horario que regula el Plan de Lealtad es el del centro de la Ciudad de México conforme a la hora oficial establecida por el Centro Nacional de Metrología,
                    por lo que cualquier ingreso de puntos en la APP se tendrá por registrado o almacenado en el momento en que sea efectivamente recibido en el servidor que contiene los
                    datos de los Participantes, atendiendo al horario señalado.

                </li>
            </ul>
            <h5 className="font-weight-bold">RESTRICCIONES:</h5>
            <p>
                Darán motivo a la anulación de puntos y/o cancelación del Registro de Participante, de manera inmediata:

            </p>
            <ul>
                <li>
                    La participación de cualquier menor de 18 años y en su caso, que no pueda acreditar su legal residencia en la República Mexicana en caso de ser extranjero.

                </li>
                <li>
                    La participación de cualquier empleado, prestador de servicios, becario, contratista, socio o directivo de Kimberly-Clark de México, S.A.B. de C.V., así como de Grupo
                    Tramando y/o cualquier persona que directa o indirectamente haya colaborado en la planeación, desarrollo u organización del Plan de Lealtad.

                </li>
                <li>
                    Cualquier ataque o intento de alteración o manipulación al sistema informático, al Sitio WEB, la APP o al lugar en el cual se encuentre radicada la información.

                </li>
                <li>
                    Descargar, copiar, atacar o intentar alterar o manipular parte o todo el contenido del Sitio WEB, la APP y/o se reproduzcan o modifiquen los puntos obtenidos.

                </li>
                <li>
                    Cuando se compruebe que el Participante ha realizado actos fraudulentos para beneficiarse del Plan de Lealtad o ha sido beneficiado por actos de terceros en
                    contravención con las Bases.

                </li>
                <li>
                    Los previstos en los demás apartados de las presentes Bases.

                </li>
                <li>
                    Realizar o ejecutar cualquier otro acto o actividad que pudiera ser considerado fraudulento o inadecuado conforme a la legislación aplicable.

                </li>
                <li>
                    Ser un distribuidor de los productos que aquí se recompensan.

                </li>
                <li>
                    Cualquier motivo de anulación de puntos y/o cancelación del Registro de Participante que haya acumulado el puntaje necesario para redimirlo, podrá implicar la anulación
                    de la entrega de cualquier bien o servicio solicitado, y el ejercicio de las acciones pertinentes para reclamar la reparación del daño o perjuicio ocasionado, en su caso.

                </li>
                <li>
                    Cuando el usuario no ha registrado algún ticket y/o generado algún canje en un periodo de 2 meses, automáticamente se dará por cancelado su registro por inactividad dentro
                    del Plan de Lealtad.
                </li>
                <li>
                    Los previstos en los demás apartados de las presentes Bases.

                </li>
            </ul>
            <h5 className="font-weight-bold">3. VIGENCIA PLAN DE LEALTAD:</h5>
            <p>
                La vigencia de los puntos dentro del plan de lealtad será de 1 año y medio (18 meses) a partir de la fecha de registro del participante.
                En el momento en que el participante cumpla el año y medio indicado y aún tenga “Hugs” acumulados, la cuenta será considerada como inactiva.

            </p>
            <h5 className="font-weight-bold">4. MECÁNICA:</h5>
            <p>
                La participación del público en general en este Plan de Lealtad implica el conocimiento y aceptación de las presentes bases y mecánica señaladas y el cumplimiento de
                requisitos establecidos en las mismas, así como la aceptación de los términos y condiciones establecidos en el Aviso de Privacidad que más adelante se indica. El presente
                Plan de Lealtad será dado a conocer mediante redes sociales de la marca patrocinadora, el sitio web correspondiente al Plan de Lealtad siendo este 
                <a href="https://www.huggies.com.mx/huggies-benefits/"> https://www.huggies.com.mx/huggies-benefits/</a> , material de comunicación en punto de venta y la APP.

            </p>
            <p>
                Para poder acceder al presente Plan de Lealtad, los consumidores deberán seguir los siguientes pasos:
            </p>
            <p>
                a. A partir de las 12:00:00 horas del día 1 de enero de 2021 los Participantes podrán descargar de la tienda de aplicaciones de su smartphone con sistema operativo Android
                o iOS, la APP denominada “Huggies® Benefits”, en la cual deberán proporcionar voluntariamente su correo electrónico, teléfono de contacto y una contraseña para obtener un
                Registro de Participante.

            </p>
            <p>
                b. Una vez que se cuente con un Registro de Participante, cada que este realice la compra de alguno de los productos participantes de este Plan de Lealtad, deberá solicitar
                su ticket de compra facturable o facturado al establecimiento, ingresar a su Cuenta por medio de la APP y registrar los tickets que amparen la compra de productos mencionados
                , asignándose al Participante un 5% en “Hugs”, del monto total de compra de productos participantes, teniendo en cuenta que dichos “Hugs” se podrán ver reflejados hasta dentro
                de las 48 horas hábiles siguientes al registro, esto con motivo de la validación del ticket que realizará la Organizadora con la cadena emisora del mismo. En el supuesto que el
                referido 5% no corresponda a un número entero, para efectos de este Plan de Lealtad se considerará únicamente el número entero inmediato anterior, no pudiendo acumularse el decimal
                o fracción correspondiente.
            </p>
            <p>
                Cabe destacar que solamente serán tomados en cuenta para la respectiva acumulación de Hugs, aquellos productos participantes amparados en el ticket de compra que aparezcan
                efectivamente pagados y con un valor positivo superior a $20.00 (veinte pesos 00/100 M.N.), es decir no podrán acumularse Hugs respecto de productos participantes reembolsados o
                descontados del ticket de compra con motivo de promociones u ofertas del establecimiento, debido a que no son acumulables dichas promociones u ofertas para efectos del presente
                Plan de Lealtad. <br />
            </p>
            <p>
                Se tendrá como límite máximo por hijo (máximo 3) para acumulación de “Hugs” la cantidad de 22,700 Hugs por participante durante la vigencia de la presente actividad y en su caso sus
                prórrogas, a partir de su registro, una vez llegando a este límite, los Participantes no podrán seguir acumulando “Hugs” vía registro de tickets de compra de producto participante, no
                obstante ello, se podrán seguir acumulando “Hugs” mediante las dinámicas adicionales previstas en las presentes Bases que se muestren de tiempo en tiempo en la APP.
            </p>
            <p>
                Es importante mencionar que el registro de tickets se realizará a través de la APP, utilizando la cámara de su Smartphone para tomar una fotografía del ticket que corresponda, para su
                envío dentro de dicha APP para validación y asignación de puntos por parte de la Organizadora, conforme a lo señalado en el párrafo anterior.
            </p>
            <p>
                Por otro, es resaltable que el monto máximo a considerar por cada compra será de $5,000 M.N. de producto participante, es decir, aquellos tickets y/o facturas que demuestren la compra con
                un monto superior no serán considerados; así también aquellos tickets superiores a $2,000 M.N. y hasta $5,000 M.N. que sumen productos participantes deberán de subir la factura para considerarse
                como sujetos a adquirir “Hugs.
            </p>
            <p>
                No será válido o tomado en cuenta aquel ticket y/o factura que no cumpla con los requisitos establecidos en las presentes Bases y/o se ubiquen en alguno de los siguientes supuestos:
            </p>
            <p>
                I ) No sea legible, debiendo enviar una fotografía nueva del ticket. <br />
                II) No contenga productos participantes.<br />
                III) No pueda ser facturado el monto de la compra de productos participantes, es decir que el ticket no cumpla con los requisitos fiscales aplicables; motivo por el cual los Participantes
                deberán solicitar tickets con trazabilidad fiscal, facturables. <br />
                IV) Tenga una antigüedad mayor a 3 meses.<br />
                V) Haya sido registrado con anterioridad en el presente Plan de Lealtad.<br />
                VI) Que el producto reflejado en los tickets de compra haya sido devuelto a la tienda.<br />
                VII) Que se presenten tachaduras o modificaciones en el ticket.<br />
                VIII)  Aquellos tickets cuya suma de productos participantes sean superiores a $2,000.00 (dos mil pesos 00/100) y no vengan acompañados de una factura timbrada con forme lo indica la
                autoridad tributaria.<br />
                IX) Tickets y/o Facturas superiores a $5,000 M.N.del monto total de compra de productos participantes.<br />
                X) Que el ticket y/o factura presentado en la plataforma contenga leyendas que indiquen ser copia o reimpresión.

            </p>
            <p>
                En caso que la APP tuviera alguna falla y no pudiera ser registrado el ticket de compra correspondiente, esta mostrará el siguiente mensaje: “Por el momento no es posible “subir” tu ticket, por
                favor inténtalo más tarde”. En este supuesto, el Participante podrá comunicarse por los medios establecidos en las presentes Bases y reportar la falla técnica conforme al primer párrafo del
                apartado 7 siguiente. <br />
            </p>
            <p>
                c. Los Participantes podrán acumular Hugs adicionales al completar de manera correcta las dinámicas contenidas dentro de la APP y que se muestren de tiempo en tiempo, las cuales deberán
                cumplirse bajo sus propias reglas o condiciones y aportarán los puntos ahí precisados. <br />
                Además, los Participantes tendrán la posibilidad de compartir un código específico contenido dentro de la APP y que así sea referido expresamente, a cualquier persona a la que se desee invitar a
                registrarse por primera vez en la APP y participe en el presente Plan de Lealtad. Para efectos del Plan de Lealtad no se considerará como invitado o participante nuevo o registrado por primera vez, a
                aquel que ya haya formado parte de este Plan de Lealtad, aun cuando se haya dado de baja y pretenda registrarse
                nuevamente. Cumpliendo los requisitos mencionados, cada nuevo Participante invitado recibirá un monto de Hugs estipulados en su momento dentro del plan de lealtad, puntaje que se otorgará por única ocasión,
                es decir no podrán recibir dichos Hugs en el supuesto que ya se haya redimido un código de invitado previamente, y el Participante que haya compartido el código para invitar al nuevo Participante recibirá
                el monto de Hugs definidos en ese momento cuando este último descargue la APP, complete su registro e ingrese un ticket válido.  Para efectos que el nuevo Participante pueda redimir su código de invitado
                atendiendo a las reglas antes mencionadas, deberá registrarse y, completar su registro o perfil ingresando el código de referidos en ese momento, de lo contrario el código no podrá ser utilizado posteriormente. <br />
            </p>
            <p>
                Asimismo, en el presente Plan de Lealtad podrán ser utilizados los códigos digitales que amparen puntos contenidos en los productos participantes, y que los Participantes hayan obtenido en el Sitio WEB en dinámicas
                distintas o independientes a las previstas en las presentes Bases   dentro de la vigencia estipulada en cada código. <br />
            </p>
            <p>
                NOTA: Es importante aclarar que, cualquier código físico o digital susceptible de ser ingresado, registrado o utilizado dentro de la APP para la acumulación de puntos, independientemente de su dinámica o procedimiento
                de redención, será considerado válido o útil por una sola ocasión durante toda la vigencia del presente Plan de Lealtad y asignará el puntaje específico que dicho código indique, y a falta de disposición expresa sobre
                el puntaje a otorgar, estos códigos podrían ser de diferetes denominaciones dentro de la vigencia estipulada en cada código. <br />
            </p>
            <p>
                d. Es importante mencionar que entre más se registren tickets y se utilice la APP mediante el cumplimiento de dinámicas dentro de la misma, los Participantes obtendrán un mayor nivel o ranking de los mencionados más adelante,
                con el que podrán tener acceso a mejores incentivos o recompensas, las que podrán visualizar en la APP. Si durante un periodo de 3 meses un Participante no registra tickets u obtiene hugs dentro de la APP, se entenderá que el
                Participante que corresponda renuncia a cualquier incentivo y por ende perderá todo su puntaje hasta ese momento obtenido, con lo cual están completamente de acuerdo todos los Participantes del presente Plan de Lealtad. <br />
                De igual manera, cabe destacar que existirán 4 niveles o ranking´s de participación, de acuerdo con el número de hugs obtenidos, alcanzables mediante el registro de tickets, uso de la APP conforme a lo anteriormente señalado
                y/o mediante cualquier otra forma de acumulación prevista de manera expresa en el presente Plan de Lealtad. Los niveles serán los siguientes: <br />

                I) Huggies® Pink: Es el nivel inicial para todos los participantes, con hasta 2,000 hugs.<br />
                II) Huggies® Red: Se alcanzará al obtener de 2,001 hugs a 4,000 hugs.<br />
                III) Huggies® Gold: Se alcanzará al obtener de 4,001 hugs a 7,000 hugs.<br />
                IV) Huggies® Diamond: Se alcanzará al obtener 7,001 hugs a 22,700.<br />
            </p>
            <p>
                e. Los Participantes obtendrán o acumularán todo el puntaje o “hugs” que les sea posible conforme a las presentes Bases, con los cuales podrán adquirir, canjear o solicitar alguno de los
                incentivos o recompensas ofrecidas y mostradas de acuerdo con el Nivel o Ranking con el que cuenten, en el catálogo disponible en la APP y que la Organizadora podrá actualizar en cualquier
                momento y a entera discreción, respetándose en todo momento la entrega de incentivos o recompensas solicitadas con anterioridad al retiro o actualización de alguno de estos en el catálogo. <br />
                En caso de no solicitar o redimir algún incentivo o recompensa durante la vigencia estipulada para el presente Plan de Lealtad, los puntos acumulados se perderán
            </p>
            <h5 className="font-weight-bold">5. CATÁLOGO Y PROCEDIMIENTO DE ENTREGA DE INCENTIVOS O RECOMPENSAS:</h5>
            <p>
                Dentro de la APP existirá un catálogo de incentivos o recompensas que los Participantes podrán adquirir, solicitar o redimir con los puntos que hayan acumulado y de acuerdo al nivel o ranking que
                tengan al momento de solicitarlo, en razón de su actividad o inactividad mediante el uso de la APP, registro de tickets o acumulación de hugs mediante cualquier otra forma prevista de manera expresa
                en el presente Plan de Lealtad, siempre y cuando el Participante tenga un ticket registrado y validado por el Organizador o, un código de digitalización registrado en su cuenta. <br />
            </p>
            <p>
                Dicho catálogo de incentivos o recompensas con su respectiva equivalencia en puntos, podrá ser actualizado de tiempo en tiempo y en cualquier momento a entera discreción por la Organizadora,
                para lo cual se señalará en su caso el plazo durante el cual estarán disponibles los incentivos o recompensas respectivas, respetándose en todo momento la entrega de incentivos o recompensas solicitadas
                con anterioridad al retiro o actualización de alguno de estos en el catálogo.
            </p>
            <p>
                Los incentivos o recompensas ofrecidas a ser adquiridas o redimidas con los puntos acumulados mediante el presente Plan de Lealtad, serán entregados por la Organizadora al Participante que corresponda,
                de acuerdo a la disponibilidad de los incentivos y siguiendo las reglas, plazos, vigencias o procedimientos especificados para cada incentivo, los cuales dependerán y/o son emitidos o proporcionados por
                distintos proveedores. <br />
            </p>
            <p>
                Es importante mencionar que respecto de todo incentivo que se pretenda redimir, la Organizadora podrá solicitar al Participante algunos datos o documentos adicionales para la adecuada redención, siendo domicilio
                completo y comprobante del mismo, los cuales deberán ser enviados dentro de los 5 días hábiles siguientes a aquel en que se le soliciten o el plazo expresamente señalado, de lo contrario el incentivo no podrá
                ser entregado o redimido y la solicitud se tendrá por no hecha. <br />
            </p>
            <p>
                Para todo incentivo físico a entregar o redimir que se envíe por paquetería, la Organizadora podrá contactar al Participante vía telefónica y/o al correo electrónico registrados, para solicitar o confirmar el
                domicilio y código postal de envío; si durante 10 días hábiles no es posible contactarle, los hugs que se hayan utilizado para solicitar el incentivo que corresponda, serán devueltos de manera automática a la
                cuenta del Participante, enviando la Organizadora un correo electrónico confirmando dicho movimiento. Cuando efectivamente se envíe el incentivo, se deberá confirmar de recibido dentro de un periodo máximo de
                48 horas hábiles a partir de la fecha en que se le confirme el envío de este, de lo contrario se entenderá que el incentivo fue entregado y recibido en tiempo y forma, por lo cual no habrá derecho de reclamación o queja alguna.
            </p>
            <p>
                Los incentivos físicos enviados por mensajería directamente a un domicilio, únicamente consideran un máximo de tres intentos de entrega en el domicilio especificado por el participante, y que en caso de no localizar
                al participante, se le notificará vía telefónica o vía correo electrónico que su premio se dejará en el sistema “OCURRE” de la mensajería más cercana a su domicilio para que sea el participante quien recoja su premio,
                teniendo hasta 48 horas máximo para recogerlo, de lo contrario este beneficio se pierde, sin opción a tener una bonificación de sus puntos. <br />
            </p>
            <p>
                En el caso de incentivos digitales, el participante tendrá hasta 24 horas hábiles para confirmar de recibido, de lo contrario se entenderá que el incentivo fue entregado y recibido en tiempo y forma, por lo cual no habrá derecho de reclamación o queja alguna.
            </p>
            <p>
                Los errores en la captura de información por el Participante en su Cuenta son responsabilidad de éste en todo momento.<br />
            </p>
            <p>
                Los incentivos y/o los hugs, no son transferibles ni comercializables y no se podrá reclamar su equivalente en dinero en efectivo por ningún motivo; salvo que la APP del plan de lealtad ofrezca expresamente un medio de equivalencia en
                dinero para su pago <br />
            </p>
            <p>
                La Organizadora se reserva el derecho de no canjear o entregar algún incentivo o recompensa, en caso de comprobar la existencia de conductas fraudulentas por parte de los Participantes que resulten Acreedores al incentivo
                (por ejemplo: alteración de identificaciones, falsedad o alteración de datos e/o información) sin que sea necesario acreditar ante autoridad alguna evidencia de dicha conducta fraudulenta del supuesto Acreedor al Incentivo.

            </p>
            <h5 className="font-weight-bold">6. GARANTÍA DE LOS INCENTIVOS OFRECIDOS:</h5>
            <p>
                La garantía sobre los incentivos ofrecidos la proporcionará el proveedor de estos, de conformidad con los términos y condiciones establecidos por dicho proveedor, los cuales podrán ser consultados o estarán referidos en el catálogo
                o en el propio incentivo.
            </p>
            <h5 className="font-weight-bold">7. CONSIDERACIONES GENERALES:</h5>
            <ul>
                <li>En caso que cualquier Participante tuviese algún problema dentro del la APP del Plan de Lealtad, deberá de comunicarse de inmediato al teléfono de dudas, comentarios e información del presente Plan de Lealtad, para reportarlo y se atienda su requerimiento a la brevedad. Cualquier error u omisión no reportado, podría no ser atendido por la Organizadora. En caso que el Participante no reporte la anomalía o error dentro de las siguientes 48 horas a partir de que se suscite o se dé por enterado, podría no registrarse y/o en consecuencia perder la oportunidad de recibir algún incentivo que pudiera corresponderle, sin que tenga responsabilidad alguna la Organizadora.
</li>
                <li>La Organizadora no será responsable por fallas técnicas en el sistema de captura de datos, ni de la ejecución o desempeño de la dinámica de participación en el Plan de Lealtad, ya que esto depende tanto de los Participantes como de terceros ajenos.
</li>
                <li>La Organizadora podrá realizar en cualquier momento cambios en el contenido de las Bases del Plan de Lealtad, siendo que estas se comunicarán y publicarán en el Sitio WEB y en la APP, con lo cual están completamente de acuerdo los Participantes, debiendo consultar de manera frecuente dichas Bases.
</li>
                <li> La Organizadora no asumirá la cobertura de restricciones, limitaciones, rechazos, revocaciones o anulaciones que impidan al Participante hacer su registro de participación. Por ende, la Organizadora no se hace responsable por causas derivadas de caso fortuito o fuerza mayor, en razón de no ser actos que dependan directa o indirectamente de ésta.
</li>
                <li>La Organizadora podrá en cualquier momento suspender el Plan de Lealtad o la adquisición, canje y entrega de incentivos, sin responsabilidad alguna, si dicha suspensión es en acato de cualquier orden administrativa o judicial y/o si se dieren circunstancias de caso fortuito o fuerza mayor o eventos que afecten su ejecución o a sus intereses. Si por causas ajenas a la Organizadora el Plan de Lealtad se suspendiera, modificara, alterara o de cualquier forma se vieran imposibilitados los Participantes para recibir el incentivo en caso de tener derecho a ello, ésta se reserva el derecho de otorgar opcionalmente algún incentivo igual o similar al incentivo que el Participante se hizo acreedor. En todos estos casos la Organizadora del Plan de Lealtad publicará a través del Sitio WEB y la APP los motivos en que se fundamenta la realización de cualquiera de los actos establecidos en el presente párrafo.
</li>
                <li>La Organizadora no será responsable por el uso que se le brinde a cualquiera de los incentivos o recompensas redimidas, ni por procesos judiciales, extrajudiciales o de cualquier otra índole que puedan ser interpuestos con relación a un reclamo de cualquier tipo de responsabilidad, por hechos acontecidos durante o como consecuencia del canje o redención de los mismos, así como relacionados con garantías por daños, pérdidas o perjuicios ocasionados por el uso y disfrute de dichos incentivos.
</li>
                <li> Cualquier acreedor al incentivo libera de toda responsabilidad a la Organizadora del Plan de Lealtad conforme a lo establecido en estas Bases, al momento de realizar el canje o redención de algún incentivo.
</li>
                <li>La Organizadora iniciará las acciones legales pertinentes contra aquellos Participantes que pretendan defraudar, engañar o burlar las reglas y procedimientos del Plan de Lealtad y/o afectar la participación de los demás Participantes.
</li>
            </ul>
            <h5 className="font-weight-bold">8. AUTORIZACIÓN DE USO DE DATOS, OBRA E/O IMAGEN:</h5>
            <p>
                El participante al formar parte del plan de lealtad, autoriza expresamente a la organizadora de este y se obliga a suscribir cualquier documento que resulte necesario en favor de ésta y/o de Kimberly-Clark de México, S.A.B. de C.V., que tenga como finalidad fijar y difundir su imagen, así como su imagen y/o voz, para la elaboración de material fotográfico o testimonial del plan de lealtad y su difusión en los medios de comunicación conocidos o por conocerse que la organizadora de dicho plan de lealtad juzgue convenientes. El participante acreedor, por el simple hecho de haber recibido el incentivo se obliga a no revocar tal autorización.
            </p>
            <p>
                Los testimoniales a que se hacen referencia en el párrafo anterior y en su caso, cualquier fotografía u obra producida por los participantes derivado y compartida durante y para el desarrollo o ejecución del plan de lealtad, serán propiedad exclusiva de la organizadora del plan de lealtad o de Kimberly-Clark de México, S.A.B. de C.V., los cuales podrá difundir y utilizar de la manera que más convenga a sus intereses, sin que esto implique pago alguno a dichos participantes por su aparición en cualquier medio de comunicación.
            </p>
            <p>
                En consideración a las autorizaciones otorgadas conforme a los párrafos anteriores, los participantes ceden en favor de la organizadora y/o de Kimberly-Clark de México, S.A.B. de C.V. de manera gratuita, es decir sin contraprestación a cambio alguna, de manera indefinida todos los derechos tangibles e intangibles de propiedad intelectual que en su caso pudieran corresponderles sobre los videos o materiales generados, para su explotación, difusión, comunicación al público y puesta a disposición del público en todos los territorios y con la frecuencia de repetición que convenga a sus intereses y/o a cualquiera de sus empresas controladoras, subsidiarias, afiliadas, licenciatarias, representantes legales, cesionarios y/o cualquier entidad relacionada con o que éstas indiquen, en los que estén contenidos su voz y/o imagen, así como cualquier interpretación y/o ejecución artística o parte de las mismas, utilizando los medios técnicos y tecnológicos conocidos en la actualidad y los que pudieran desarrollarse en el futuro, y para cualquier tipo de aplicación o medio de difusión conforme convenga a sus intereses, autorizando además a efectuar cambios, modificaciones, adiciones y supresiones de dichos materiales sin limitación alguna.
            </p>
            <h5 className="font-weight-bold">9. JURISDICCIÓN APLICABLE:</h5>
            <p>
                Cualquier controversia suscitada con motivo del Plan de Lealtad, se regirá por las presentes Bases y como complemento para lo establecido en éstas, aplicará la jurisdicción de los tribunales y leyes aplicables en la Ciudad de México, sujetándose todas las partes interesadas en la misma incluyendo a los Participantes, quienes renuncian a cualquier otra jurisdicción a la que por beneficio de su domicilio presente o futuro, o cualquier otra circunstancia pudiese corresponderles.
            </p>
            <h5 className="font-weight-bold">10. AVISO DE PRIVACIDAD:</h5>
            <p>
                Todos los usuarios de este sitio de internet, al inscribirse aceptan expresamente el siguiente aviso de privacidad, por eso le pedimos que antes de continuar y registrarse, lea con detenimiento, el tratamiento de los datos personales a los que tendremos acceso (si usted nos los proporciona) y a los derechos que a continuación se describen.
                AVISO DE PRIVACIDAD

            </p>
            <h5 className="font-weight-bold">RESPONSABLE</h5>
            <p>
                Kimberly-Clark de México, S.A.B. de C.V. (en adelante “KCM”), ubicado en la calle Jaime Balmes No.8, piso 9 Col. Los Morales Polanco, C.P. 11560, Ciudad de México es la responsable del debido tratamiento y resguardo de los datos personales que son recabados a través de la aplicación Huggies® Benefits.
            </p>
            <h5 className="font-weight-bold">DATOS REQUERIDOS</h5>
            <p>
                Los datos que se requieren al registrase en la aplicación Huggies® Benefits son los siguientes: I) Nombre y apellidos del usuario; II) cuenta de correo electrónico; III) fecha de nacimiento (año, mes y día); IV) género (sexo); V) domicilio de residencia; VI) estado civil; VII) teléfono celular de contacto; y VIII) nombres y fechas de nacimiento de los hijos del participante.
            </p>
            <p>
                Tal y como se señala en nuestro Aviso de Privacidad <a href="https://www.huggies.com.mx/aviso-de-privacidad"> https://www.huggies.com.mx/aviso-de-privacidad</a> , al registrarse en nuestra aplicación usted otorga su consentimiento respecto a los datos proporcionados y requeridos de manera manual, al teclear en su ordenador o dispositivo móvil la información indicada en el párrafo inmediato anterior.<br />
            </p>
            <p>
                DATOS PERSONALES SENSIBLES.- Es oportuno señalar que los datos sensibles que pudieran recabarse para el caso del registro mediante la red social Facebook®, Twitter® y Google+® y que sean considerados sensibles en los términos de la Ley Federal de Protección de datos Personales en Posesión de los Particulares, se hace de su conocimiento para que el consentimiento que se otorgue al momento de aceptar nuestro Aviso de Privacidad, sea expreso y consiente de dicha circunstancia.
            </p>
            <h5 className="font-weight-bold">FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES</h5>
            <p>
                Los datos arriba listados se solicitan para: <br />
                Comercializar productos y servicios ofrecidos por KCM y sus empresas afiliadas y subsidiarias.<br />
            </p>
            <p>
                Ofrecer al usuario de la aplicación de Huggies® Benefits, información sobre cambios, nuevos productos y/o servicios de todas las marcas de KCM, y sus empresas afiliadas y subsidiarias.<br />
            </p>
            <p>
                Realizar campañas, por medios electrónicos, de promociones de nuevos productos y/o servicios correspondientes a la marca Huggies®, así como a todas y cada una de las marcas de KCM y sus empresas afiliadas y subsidiarias. En el entendido que, para estos efectos, se podrá utilizar la cuenta de correo electrónico proporcionada, así como las redes sociales abiertas con dicha cuenta de correo electrónico. <br />
            </p>
            <p>
                Registrarlo como Participante en los concursos y/o promociones que ofrecemos o que ofrecen nuestros proveedores, identificándolo en su caso, como ganador de estos y poder otorgarle el incentivo correspondiente. <br />
            </p>
            <p>
                Evaluar la calidad de nuestros productos y/o servicios de KCM y sus empresas afiliadas y subsidiarias.<br />
            </p>
            <p>
                Analizar los patrones de consumo de los productos y servicios que ofrece KCM y sus empresas afiliadas y subsidiarias.<br />
            </p>
            <p>
                Que exista la posibilidad de tener una relación de manera directa y personal con los clientes, a fin de acreditar la identificación del titular de dichos datos personales, de conformidad con las leyes y disposiciones aplicables.<br />
            </p>
            <p>
                Analizar el perfil de gustos, consumo, preferencias e inquietudes de nuestros usuarios para ofrecerles contenido y productos de manera personalizada.<br />
                Identificar los valores de nuestros usuarios para el efecto de ser consecuentes con los mismos al momento de promocionar y ofrecer nuestros productos.<br />
                Identificar el grado de preparación académica y profesional, así como el estilo de vida de nuestros usuarios para ofrecerles y comunicarles acerca de productos acordes a sus necesidades y preferencias.

            </p>
            <h5 className="font-weight-bold">TRANSFERENCIA DE LOS DATOS</h5>
            <p>
                Los datos personales que se recaben serán compartidos en los términos del artículo 37, fracción III de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, con sociedades controladoras, subsidiarias o afiliadas bajo el control común de KCM, o a una sociedad matriz, a cualquier sociedad del mismo grupo de KCM, así como al grupo al que pertenece KCM.<br />
            </p>
            <p>
                Exceptuando lo precisado en el párrafo anterior, a ninguna otra persona o entidad distinta de KCM, le serán transferidos los datos recabados a través de los medios previstos en el Plan de Lealtad; no obstante ello, sus proveedores de servicios podrán tener acceso a los datos personales recabados, en su calidad de encargados del tratamiento de los mismos, por cuenta e instrucciones de KCM.<br />
                DERECHOS ARCO (Acceso, Rectificación, Cancelación y Oposición) y REVOCACIÓN del consentimiento para el tratamiento de los datos.<br />
            </p>
            <p>
                Para cualquier acceso, rectificación, cancelación, oposición o revocación en el manejo de los datos personales obtenidos a través de la aplicación Huggies® Benefits, KCM pone a su disposición el correo electrónico  privacidad@kcc.com , a cargo de la Gerencia de Servicios de Internet, en términos del Capítulo IV de la LFPDP, artículo 21 de su Reglamento, así como de las disposiciones contenidas en los Lineamientos del Aviso de Privacidad emitidas por la Secretaría de Economía. Y KCM se compromete a dar contestación a dicha solicitud en un plazo de 15 días a partir de que haya recibido dicha solicitud de derechos ARCO.<br />
            </p>
            <p>
                Para estos efectos, el usuario deberá enviar a dicha dirección de correo electrónico un escrito en el que señale, de manera clara y expedita, qué datos y en qué medida desea tener acceso, o bien, rectificar, cancelar, oponerse o revocar su consentimiento al tratamiento de los mismos, adjuntando la documentación que sustente su petición. Además deberá señalar el nombre de usuario y la cuenta de correo electrónico con la que se dio de la aplicación de Huggies® Benefits.<br />
            </p>
            <p>
                Una vez que sea recibida la petición debidamente requisitada en la cuenta de correo electrónico ya señalada, KCM contará con un plazo de veinte días (mismos que podrán ser ampliados por un periodo igual, dependiendo de las circunstancias del caso) para resolver la petición correspondiente.<br />
            </p>
            <p>
                De resultar procedente la petición del usuario, KCM deberá cumplir con la misma en un plazo de quince días contados a partir de la emisión de la resolución correspondiente.
            </p>
            <h5 className="font-weight-bold">OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES</h5>
            <p>
                El límite en cuanto al uso y divulgación de los datos personales recabados a través del portal  <a href="https://www.huggies.com.mx/huggies-benefits/y/o"> https://www.huggies.com.mx/huggies-benefits/y/o</a> la aplicación Huggies® Benefits, se encuentra circunscrito al Aviso de Privacidad expuestas en la dirección electrónica (<a herf="https://www.huggies.com.mx/aviso-de-privacidad"> https://www.huggies.com.mx/aviso-de-privacidad </a> ).<br />
            </p>
            <p>
                Una vez que el usuario decida cancelar su cuenta personal de la aplicación Huggies® Benefits., dejará de recibir todo tipo de avisos o notificaciones relativas a la misma.
            </p>
            <p>
                En caso de que un usuario únicamente decida dejar de recibir comunicaciones, notificaciones o avisos correspondientes a los productos y servicios de KCM, ésta pone a su disposición la dirección de correo electrónico privacidad@kcc.com , para que el usuario así lo manifieste de manera expresa.
            </p>
            <h5 className="font-weight-bold">MECANISMOS EN MEDIOS REMOTOS O LOCALES</h5>
            <p>
                Cuando usted interactúa dentro de la aplicación de Huggies® Benefits. podemos reunir otra clase de información no personal, como el tipo de navegador, la dirección IP y las páginas visitadas, o información sobre los servicios que usa, las búsquedas que realiza, los resultados de esas búsquedas, correos electrónicos de KCM que abre y/o envía, ofertas de KCM o vínculos a los que se conecte mediante correos electrónicos y otra información relacionada con el uso que usted hace del portal mencionado. <br />
            </p>
            <p>
                También usamos cookies y otras tecnologías para manejar, mejorar y optimizar nuestro sitio con el fin de ofrecerle una experiencia más personalizada. NO OBSTANTE, POR LOS MEDIOS ANTES SEÑALADOS, NUNCA SERÁN RECABADOS DATOS PERSONALES.
            </p>
            <h5 className="font-weight-bold">CAMBIOS AL AVISO DE PRIVACIDAD</h5>
            <p>
                En caso de realizar modificaciones a nuestro Aviso de Privacidad, enviaremos un correo electrónico a nuestros usuarios, a la cuenta con la que se dieron de alta en la aplicación Huggies® Benefits, para informarles sobre dichos cambios, mismo que incluirá el vínculo para consultarlos.
            </p>
            <h5 className="font-weight-bold">CONSENTIMIENTO DEL USUARIO</h5>
            <p>
                MENORES DE EDAD. Si eres menor de 18 (dieciocho) años de edad, antes de darnos tu nombre, dirección o correo electrónico, o cualquier otro dato personal que lo identifique obtén permiso de tus (s) padre(s) o tutor(es), toda vez que si te inscribes a nuestro portal de internet, estás manifestando bajo protesta de decir verdad, que cuentas con la autorización tácita de sus padres o tutores para otorgarnos tus datos personales. <br />
            </p>
            <p>
                Los padres o tutores, son exclusivamente responsables del acceso de los menores de edad a este sitio, toda vez que son los garantes legales del cuidado y supervisión de las acciones de sus hijos, dando las facilidades a sus hijos para el ingreso a internet como a este sitio; si por equivocación tu hijo mandó sus datos sin tu consentimiento, favor de ejercer sus derechos ARCO en representación de ellos, al correo privacidad@kcc.com
            </p>
            <p>
                Asimismo, al inscribirse a este portal, se manifiesta expresamente el consentimiento y aceptación del usuario con los términos y condiciones del presente Aviso de Privacidad, el usuario manifiesta su consentimiento expreso e inequívoco para que se dé uso y tratamiento a todos y cada uno de los datos personales señalados, así como aquellos identificados como sensibles.
            </p>
        </div>
    );
};

export default TermsAndConditions;
