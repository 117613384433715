import Api from '../utils/axios';
const api = new Api();

export const getUsers = async () => {
    const users = await api.getUsers()
    //console.log(users)
    return users.data;
}

export const createUser = async (data) => {
    const usersCreated = await api.createUser(data);
    return usersCreated
}

export const getUserById = async (id) => {
    const foundUser = await api.getUserById(id);
    return foundUser
}

export const updateUser = async (id, data) => {
    const updateuser = await api.updateUser(id, data);
    return updateuser
}

export const deleteUser = async (id) => {
    const response = await api.deleteUser(id);
    return response.data
}