import axios from 'axios';
import store from '@store/store';
import { logoutUser } from '@store/reducers/auth';

const apiUrl = process.env.REACT_APP_API_URL;
const CSRF_TOKEN_KEY = 'csrfToken';
const CSRF_EXPIRY_KEY = 'csrfExpiry';
const CSRF_EXPIRY_HOURS = 11;
export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = apiUrl;
        this.csrfToken = null;
    }

    unauthorizedRequestHandler = (error) => {
        return Promise.reject(error);
    };

    getStoredCsrfToken = () => {
      const token = localStorage.getItem(CSRF_TOKEN_KEY);
      const expiry = localStorage.getItem(CSRF_EXPIRY_KEY);
      if (token && expiry && new Date().getTime() < expiry) {
        return token;
      }
      return null;
    };

    storeCsrfToken = (token) => {
      const expiryTime = new Date().getTime() + CSRF_EXPIRY_HOURS * 60 * 60 * 1000;
      localStorage.setItem(CSRF_TOKEN_KEY, token);
      localStorage.setItem(CSRF_EXPIRY_KEY, expiryTime);
    };

    init = (willRedirect = true) => {
        let headers = {
            Accept: '*/*'
        };

        const { token } = store.getState().auth;

        if (token) {
            this.api_token = token
        }

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            headers: headers,
            withCredentials: "include",
        });

        axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
        axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN';

        if (willRedirect) {
            this.client.interceptors.request.use(
              async (config) => {
                if (['put', 'post', 'delete'].includes(config.method)) {
                  this.csrfToken = this.getStoredCsrfToken();
                  if (!this.csrfToken) {
                    try {
                      const response = await this.client.get('/auth/get-csrf-token');
                      this.csrfToken = response.data.token;
                      this.storeCsrfToken(this.csrfToken);
                    } catch (error) {
                      console.error(
                        'Error al obtener o validar el token CSRF:',
                        error
                      );
                      return Promise.reject(error);
                    }
                  }
                  config.headers['X-CSRF-TOKEN'] = this.csrfToken;
                }
      
                return config;
              },
              (error) => {
                return Promise.reject(error);
              }
            );
          }

        return this.client;
    };

    // app data
    getAppData = async () => this.init().get('cms/app-data/about');
    updateAppData = async (data) => this.init().put('cms/app-data/about', data);
    updateAccumulation = async (accumulationLimit) => this.init().put(`cms/app-data/accumulation-limit?accumulationLimit=${accumulationLimit}`);

    // auth
    login = async (data) => this.init().post('/auth/login', data);

    //states
    getState = async () => this.init().get(`/Address/estados`);
    getStateById = async (id) => this.init().get(`/Address/estados/${id}`);

    // users
    getUsers = async () => this.init().get(`/users`);
    getUserById = async (id) => this.init().get(`/users/${id}`);
    createUser = async (data) => this.init().post(`/users`, data);
    updateUser = async (id, data) => this.init().put(`/users/${id}`, data);
    deleteUser = async (id) => this.init().delete(`/users/${id}`);

    // clients
    getClients = async () => this.init().get(`/clients`);
    getClientById = async (uid) => this.init().get(`/clients/${uid}`);
    createClient = async (data) => this.init().post(`/clients`, data);
    addPoints = async (uid, value) => this.init().post(`/clients/${uid}/Add-Points?value=${value}`);
    updateClient = async (uid, data) => this.init().put(`/clients/${uid}`, data);
    deleteClient = async (uid) => this.init().delete(`/clients/${uid}`);
    getAddressDelivery = async (uid) => this.init().get(`/cms/address_delivery/client/${uid}`);

    // news 
    getNews = async () => this.init().get(`/cms/news`);
    getNewsById = async (id) => this.init().get(`/cms/news/${id}`);
    createNews = async (data) => this.init().post(`/cms/news`, data);
    updateNews = async (id, data) => this.init().put(`/cms/news/${id}`, data);
    deleteNews = async (id) => this.init().delete(`/cms/news/${id}`);

    getTags = async () => this.init().get(`/cms/news/tags`);
    getTagById = async (id) => this.init().get(`/cms/news/tags/${id}`);
    createTag = async (data) => this.init().post(`/cms/news/tags`, data);
    deleteTag = async (id) => this.init().delete(`/cms/news/tags/${id}`);
    updateTags = async (id, data) => this.init().put(`/cms/news/tags/${id}`, data);

    // tickets
    getTicketsNotValid = async (status) => this.init().get(`/cms/tickets/not-valid`);
    getTickets = async (status) => this.init().get(`/cms/tickets?status=${status}`);
    getProduct = async (idStore) => this.init().get(`/cms/products/all/store/${idStore}`);
    getTicketById = async (id) => this.init().get(`/cms/tickets/${id}`);
    addProductTicket = async (id, data) => this.init().post(`/cms/tickets/${id}/product`, data);
    deleteTicketProduct = async (idTicket, idProductTicket) => this.init().delete(`/cms/tickets/${idTicket}/product-ticket/${idProductTicket}`);
    updateTicket = async (id, data) => this.init().put(`/cms/tickets/${id}`, data);
    updateTicketByIdProduct = async (id, data) => this.init().put(`/cms/tickets/${id}/products`, data);
    patchTicket = async (id, newStatus, uid) => this.init().patch(`/cms/tickets/Change-Status/${id}?newStatus=${newStatus}&uid=${uid}`);

    // stores
    getStores = async () => this.init().get(`/cms/stores`);

    // donations
    getDonations = async () => this.init().get(`/cms/donations`);
    getDonationsById = async (id) => this.init().get(`/cms/donations/${id}`);
    createDonation = async (data) => this.init().post(`/cms/donations`, data);
    deleteDonations = async (id) => this.init().delete(`/cms/donations/${id}`);
    updateDonations = async (id, data) => this.init().put(`/cms/donations/${id}`, data);

    // rewords
    getCategories = async () => this.init().get(`/cms/benefits/categories`);
    postCategories = async (data) => this.init().post(`/cms/benefits/categories`, data);
    getSubCategoryById = async (id) => this.init().get(`/cms/benefits/categories/${id}`);
    delCategory = async (id) => this.init().delete(`/cms/benefits/categories/${id}`);
    putCategory = async (id, data) => this.init().put(`/cms/benefits/categories/${id}`, data);
    putSubcategory = async (id, data) => this.init().put(`/cms/benefits/categories/subcategories/${id}`, data);
    deleteSubcategory = async (id) => this.init().delete(`/cms/benefits/categories/subcategories/${id}`);
    postSubcategory = async (data) => this.init().post(`/cms/benefits/categories/subcategories`, data);
    getSubCategories = async () => this.init().get(`/cms/benefits/categories/2/subcategories`);
    getSubCategoriesByCategory = async (id) => this.init().get(`/cms/benefits/categories/${id}/subcategories`);
    getRewords = async () => this.init().get(`/cms/benefits`);
    getRewordsById = async (id) => this.init().get(`/cms/benefits/${id}`);
    createReword = async (data) => this.init().post(`/cms/benefits`, data);
    deleteReword = async (id) => this.init().delete(`/cms/benefits/${id}`);
    updateRewords = async (id, data) => this.init().put(`/cms/benefits/${id}`, data);
    getRewordsRedeem = async () => this.init().get(`/cms/benefits/redeem`);
    getRewardsRedeemDigitals = async () => this.init().get(`/cms/benefits/redeem/digitals`);
    getByIdClientIdRedeem = async (idUser, idRedeem) => this.init().get(`/cms/benefits/${idUser}/redeem/${idRedeem}`);
    patchRewords = async (idUser, idRedeem, data) => this.init().patch(`/cms/benefits/${idUser}/redeem/${idRedeem}`, data);

    // report
    getReport = async (startDate, endDate) => this.init().get(`/reports/tickets/rejected?startDate=${startDate}&endDate=${endDate}`);

    // notifications
    getNotifications = async () => this.init().get(`/cms/notifications`);
    getNotificationById = async (id) => this.init().get(`/cms/notifications/${id}`);
    createNotifications = async (data) => this.init().post(`/cms/notifications`, data);
    deleteNotification = async (id) => this.init().delete(`/cms/notifications/${id}`);
    updateNotification = async (id, data) => this.init().put(`/cms/notifications/${id}`, data);
    getNotificationClientsById = async (id) => this.init().get(`/cms/notifications/clients/${id}`);
    resendNotification = async (data) => this.init().post(`/cms/notifications/resend/${id}`);
    deleteNotificationWeb = async (id) => this.init().delete(`/cms/notifications/${id}`);


    // files
    uploadFile = async (data) => this.init().post(`file/upload`, data)
    uploadFileReward = async (id, data) => this.init().post(`File/Upload/Reward/${id}`, data)
    delUploadFileReward = async (id) => this.init().delete(`File/${id}`)
    getFile = async (id) => this.init().get(`files/${id}`)
    getFiles = async () => this.init().get(`files`)
    deleteFile = async (id) => this.init().delete(`files/${id}`)

    // reports
    filterUsers = async () => this.init().get(`/reports/users`)
    filterStore = async (startDate, endDate) => this.init().post(`/reports/tickets/state?startDate=${startDate}&endDate=${endDate}`)
    ticketsStore = async (startDate, endDate) => this.init().post(`/reports/tickets/store?startDate=${startDate}&endDate=${endDate}`)
    filterRejected = async (startDate, endDate) => this.init().post(`/reports/tickets/rejected?startDate=${startDate}&endDate=${endDate}`)
    filterSeen = async (startDate, endDate) => this.init().post(`/reports/notifications/seen?startDate=${startDate}&endDate=${endDate}`)
    filterPoints = async (startDate, endDate) => this.init().get(`/reports/users/points?startDate=${startDate}&endDate=${endDate}`)
    filterSku = async (startDate, endDate) => this.init().post(`/reports/tickets/sku?startDate=${startDate}&endDate=${endDate}`)
    filterBabies = async () => this.init().get(`/reports/users/babies`)
    getPromotionInformation = async (type) => this.init().get(`/reports/promotions?type=${type}`);
    getPromotionReports = async (type) => this.init().get(`/reports/redeemed-promotions?type=${type}`);

    // promotions
    getPromotions = async () => this.init().get(`/cms/promotions`);
    getPromotionById = async (id) => this.init().get(`/cms/promotions/${id}`);
    createPromotion = async (data) => this.init().post(`/cms/promotions`, data);
    updatePromotion = async (id, data) => this.init().put(`/cms/promotions/${id}`, data);
    deletePromotion = async (id) => this.init().delete(`/cms/promotions/${id}`);

    // DiaryBabys
    getDiaryBabys = async () => this.init().get(`/DiaryBaby`);
    getDiaryBabysById = async (id) => this.init().get(`/DiaryBaby/${id}`);
    createDiaryBabys = async (data) => this.init().post(`/DiaryBaby`, data);
    updateDiaryBabys = async (id, data) => this.init().put(`/DiaryBaby/${id}`, data);
    deleteDiaryBabys = async (id) => this.init().delete(`/DiaryBaby/${id}`);

    // CategoryBabys
    getCategoryBaby = async () => this.init().get(`/CategoryBaby`);
    getCategoryBabyById = async (id) => this.init().get(`/CategoryBaby/${id}`);
    createCategoryBaby = async (data) => this.init().post(`/CategoryBaby`, data);
    updateCategoryBaby = async (id, data) => this.init().put(`/CategoryBaby/${id}`, data);
    deleteCategoryBaby = async (id) => this.init().delete(`/CategoryBaby/${id}`);

    // AdviceConsejos
    getAdvice = async () => this.init().get(`/cms/advice`);
    getAdviceById = async (id) => this.init().get(`/cms/advice/${id}`);
    createAdvice = async (data) => this.init().post(`/cms/advice`, data);
    updateAdvice = async (id, data) => this.init().put(`/cms/advice/${id}`, data);
    deleteAdvice = async (id) => this.init().delete(`/cms/advice/${id}`);

    // BabyWeight
    //weighing
    getWeighing = async () => this.init().get(`/weighing`);
    getWeighingById = async (id) => this.init().get(`/weighing/${id}`);
    createWeighing = async (data) => this.init().post(`/weighing`, data);
    updateWeighing = async (id, data) => this.init().put(`/weighing/${id}`, data);
    deleteWeighing = async (id) => this.init().delete(`/weighing/${id}`);

    //Stage
    getStage = async () => this.init().get(`/stage`);
    getStageById = async (id) => this.init().get(`/stage/${id}`);
    createStage = async (data) => this.init().post(`/stage`, data);
    updateStage = async (id, data) => this.init().put(`/stage/${id}`, data);
    deleteStage = async (id) => this.init().delete(`/stage/${id}`);

    //recommendation
    getRecommendation = async () => this.init().get(`/recommendation`);
    getRecommendationById = async (id) => this.init().get(`/recommendation/${id}`);
    createRecommendation = async (data) => this.init().post(`/recommendation`, data);
    updateRecommendation = async (id, data) => this.init().put(`/recommendation/${id}`, data);
    deleteRecommendation = async (id) => this.init().delete(`/recommendation/${id}`);

    // Faqs
    getFaqs = async () => this.init().get(`/cms/frequent_questions`);
    deleteFaqs = async (id) => this.init().delete(`/cms/frequent_questions/${id}`);
    postFaqs = async (data) => this.init().post(`/cms/frequent_questions`, data);
    putFaqs = async (id, data) => this.init().put(`/cms/frequent_questions/${id}`, data);

    // Terms
    getTerms = async () => this.init().get(`/cms/legal_term`);
    deleteTerms = async (id) => this.init().delete(`/cms/legal_term/${id}`);
    postTerms = async (data) => this.init().post(`/cms/legal_term`, data);
    putTerms = async (id, data) => this.init().put(`/cms/legal_term/${id}`, data);

    // Reports
    getReportBase64 = async () => this.init().get(`/cms/benefits/redeem/report`);

    // Packages
    getListPackages = async () => this.init().get(`/EnvioTodoAdmin/package`);
    createPackage = async (id, data) => this.init().post(`/EnvioTodoAdmin/package/${id}`, data);
    UpdateStatusPackage = async (data) => this.init().post(`/EnvioTodoAdmin/status_package`, data);
    getListPackagesAvalaible = async (id) => this.init().post(`/EnvioTodoAdmin/quote_package/${id}`);
    createOrder = async (id, data) => this.init().post(`/EnvioTodoAdmin/order_package/${id}`, data);
    createGather = async (id, data) => this.init().post(`/EnvioTodoAdmin/gather_package/${id}`, data);

    // Stores
    createStore = async (data) => this.init().post(`/cms/stores`, data);
    getStores = async () => this.init().get(`/cms/stores`);
    getStoreById = async (id) => this.init().get(`/cms/stores/${id}`);
    deleteStore = async (id) => this.init().delete(`/cms/stores/${id}`);
    updateStore = async (id,data) => this.init().put(`/cms/stores/${id}`, data);
    getProductsByStores = async (id) => this.init().get(`/cms/products/all/store/${id}`);
    getProducts = async () => this.init().get(`/cms/products`);
    deleteProductOnStore = async (prod, id) => this.init().delete(`/cms/stores/ProductsId/${prod}/CadenaId/${id}`);
    addProductToStore = async (data) => this.init().post(`/cms/stores/product/`, data);
}