import {setLoading} from '@app/store/reducers/ui';
import {Button, Input} from '@components';
import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useParams, useHistory} from 'react-router-dom';
import * as Yup from 'yup';
import {Modal} from 'react-bootstrap';
import Api from '../../utils/axios';

const EditCategory = () => {
    const api = new Api();
    const history = useHistory();
    const [rewords, setRewards] = useState(false);
    const [categories, setCategories] = useState();
    const [subCategories, setSubCategories] = useState();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [t] = useTranslation();
    const {id} = useParams();
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            categoryId: id || 0,
            categoryName: '',
            name: '',
            id: '',
            subcategory: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('Campo requerido.')),
            id: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values) => {
            dispatch(setLoading(true));
            api.putSubcategory(formik?.values?.id, {
                Name: formik?.values?.name,
                Status: 1,
                categoryId: id
            })
                .then(() => {
                    api.getSubCategoryById(id)
                        .then((response) => {
                            formik?.setFieldValue(
                                'categoryName',
                                response?.data?.name
                            );
                            formik?.setFieldValue(
                                'categoryId',
                                response?.data?.id
                            );
                            api.getSubCategoriesByCategory(
                                formik?.values?.categoryId
                            )
                                .then((res) => {
                                    dispatch(setLoading(false));
                                    setSubCategories(res.data);
                                    setOpen(false);
                                })
                                .catch(() => {
                                    dispatch(setLoading(false));
                                });
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    const formikCreate = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values) => {
            dispatch(setLoading(true));
            api.postSubcategory({
                Name: values?.name,
                Status: 1,
                categoryId: id
            })
                .then(() => {
                    api.getSubCategoriesByCategory(id)
                        .then((res) => {
                            setOpen2(false);
                            dispatch(setLoading(false));
                            setSubCategories(res.data);
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    useEffect(() => {
        dispatch(setLoading(true));
        api.getSubCategoryById(id)
            .then((response) => {
                formik?.setFieldValue('categoryName', response?.data?.name);
                formik?.setFieldValue('categoryId', response?.data?.id);
                api.getSubCategoriesByCategory(formik?.values?.categoryId)
                    .then((res) => {
                        dispatch(setLoading(false));
                        setSubCategories(res.data);
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    }, []);

    useEffect(() => {
        if (formik?.values?.categoryId) {
            api.getSubCategoriesByCategory(formik?.values?.categoryId)
                .then((response) => {
                    dispatch(setLoading(false));
                    setSubCategories(response.data);
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
            dispatch(setLoading(true));
        }
    }, [formik?.values?.categoryId]);

    const handleDeleteCategory = () => {
        dispatch(setLoading(true));
        api.delCategory(id)
            .then(() => {
                api.getCategories()
                    .then((response) => {
                        setCategories(response?.data);
                        dispatch(setLoading(false));
                        history.push(`/categories`)
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleDeleteSubCategory = (idSub) => {
        dispatch(setLoading(true));
        api.deleteSubcategory(idSub)
            .then(() => {
                api.getSubCategoriesByCategory(id)
                    .then((res) => {
                        setOpen(false);
                        dispatch(setLoading(false));
                        setSubCategories(res.data);
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const handleUpdateCategory = () => {
        dispatch(setLoading(true));
        api.putCategory(id, {
            Name: formik?.values?.categoryName
        })
            .then(() => {
                api.getCategories()
                    .then((response) => {
                        setCategories(response?.data);
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    return (
        <div>
            {/* <ContentHeader title={t('users.label.edit')} buttons={headerButtons} /> */}
            <div>
                <Modal centered show={open} onHide={() => setOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Editar Subcategoria
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-6">
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps(
                                            'name'
                                        )}
                                        type="text"
                                        value={formik?.values?.name}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div>
                <Modal centered show={open2} onHide={() => setOpen2(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Nueva Subcategoria
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formikCreate.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-6">
                                    <Input
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'name'
                                        )}
                                        type="text"
                                        value={formikCreate?.values?.name}
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen2(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

            <div className="container-fluid">
                <div className="card">
                    <div className="p-2 d-flex justify-content-between w-100">
                        <h3 className="text-danger font-weight-bold">
                            Editar categoria
                        </h3>
                        <div
                            className="gap-3 d-flex align-items-center"
                            style={{gap: '20px'}}
                        >
                            <Button
                                // isLoading={rewords}
                                type="submit"
                                onClick={handleDeleteCategory}
                                className="btn btn-danger"
                            >
                                <i className="icon-button fa fa-trash" />
                            </Button>
                        </div>
                    </div>
                    <div className="p-3">
                        <form
                            className="form-horizontal"
                            onSubmit={formik.handleSubmit}
                        >
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group row">
                                        <label>Categoria</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(
                                                'categoryName'
                                            )}
                                            type="text"
                                            value={formik?.values?.categoryName}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <h3 className="text-danger font-weight-bold">
                                            Subcategoria
                                        </h3>
                                    </div>
                                    <div className="row" style={{gap: '20px'}}>
                                        {subCategories?.map((val, k) => (
                                            <div
                                                className="btn btn-danger px-3 d-flex align-items-center"
                                                key={k}
                                            >
                                                <Button
                                                    isLoading={rewords}
                                                    type="button"
                                                    onClick={() =>
                                                        handleDeleteSubCategory(
                                                            val?.id
                                                        )
                                                    }
                                                    className="btn btn-danger"
                                                >
                                                    <i className="icon-button fa fa-trash" />
                                                </Button>
                                                <Button
                                                    isLoading={rewords}
                                                    type="button"
                                                    onClick={() => {
                                                        setOpen(true);
                                                        formik?.setFieldValue(
                                                            'id',
                                                            val?.id
                                                        );
                                                        formik?.setFieldValue(
                                                            'name',
                                                            val?.name
                                                        );
                                                    }}
                                                    className="btn btn-danger"
                                                >
                                                    <i className="icon-button fa fa-edit" />
                                                </Button>
                                                <input
                                                    value={val?.name}
                                                    className="bg-transparent border-0 text-white"
                                                />
                                            </div>
                                        ))}
                                        <Button
                                            isLoading={rewords}
                                            type="button"
                                            onClick={() => {
                                                setOpen2(true);
                                            }}
                                            className="btn btn-danger ml-2"
                                        >
                                            <i className="icon-button fa fa-plus" />
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to="/categories"
                                        className="btn text-danger"
                                    >
                                        {' '}
                                        Regresar
                                    </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        onClick={() => handleUpdateCategory()}
                                        className="w-10 btn btn-danger"
                                        isLoading={rewords}
                                        type="button"
                                    >
                                        {' '}
                                        {t('general.buttons.saveChanges')}{' '}
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategory;
