import React, { useState, useEffect } from 'react';
import { Textarea, Button, Input, Select } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as usersService from '../../services/clients';
import { prettyOnlyDate, prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import * as Yup from 'yup';
import Api from '../../utils/axios'
import { useDispatch } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { Modal } from 'react-bootstrap';

const genders = [
    {
        label: 'Sin genero',
        value: 's'
    },
    {
        label: 'Masculino',
        value: 'm'
    },
    {
        label: 'Femenino',
        value: 'f'
    }
];

const EditClients = () => {
    const history = useHistory();
    const api = new Api();
    const [clients, setClients] = useState(false);
    const [points, setPoints] = useState(false);
    const [modalPoints, setModalPoints] = useState(false);
    const [genderName, setGender] = useState();
    const [gender, setIdGender] = useState('');
    const [address, setAddress] = useState([]);
    const [genderResponse, setGenderResponse] = useState();
    const [genderId, setGenderId] = useState('');
    const [client, setClient] = useState();
    const [refresh, setRefresh] = useState();
    const [t] = useTranslation();
    const { uid } = useParams()
    const dispatch = useDispatch();
    const dataGender = [
        { id: 1, name: 'Masculino', value: 'm' },
        { id: 2, name: 'Femenino', value: 'f' }
    ]
    useEffect(() => {
        setGender(dataGender)
    }, [])

    const formik = useFormik({
        initialValues: {
            uid: '',
            name: '',
            lastName: '',
            dateOfBirth: null,
            email: '',
            gender: 's',
            pointValue: 0,
            expirationDatePoints: null,
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .max(30, t('validationSchema.max30Caracters')),
            lastName: Yup.string()
                .max(50, t('validationSchema.min50Caracters')),
            email: Yup.string()
                .max(30, t('validationSchema.max30Caracters'))
                .max(150, t('validationSchema.max150Caracters'))
                .required(t('validationSchema.requiredEmailClient'))
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });

    const formikPoints = useFormik({
        initialValues: {
            value: 0
        },
        validationSchema: Yup.object({
            value: Yup.number()
                .typeError(t('validationSchema.typeOfPoints'))
                .min(1, t('validationSchema.greaterThanOne'))
                .required(t('validationSchema.requiredPoints')),
        }),
        onSubmit: (values) => {
            addPointsClient(values);
        }
    });


    useEffect(() => {
        dispatch(setLoading(true));
        usersService.getClientById(uid)
            .then((response) => {
                formik.setValues({ ...response.data })
                setClient(response.data);
                setGenderId(response.data.gender);
                usersService
                .getAddressDelivery(uid).then((response) => {
                    setAddress(response.data);
                    console.log("address", response.data);
                    dispatch(setLoading(false));
                }).catch((error) => {
                    dispatch(setLoading(false));
                });
            }).catch(error => {
                dispatch(setLoading(false));
            })

    }, [refresh])

    const usersData = async (data) => {
        dispatch(setLoading(true));
        if (data.gender === 's') {
            data.gender = null;
        }

        if (data.dateOfBirth === "") {
            data.dateOfBirth = null;
        }

        try {
            const res = await usersService.updateClient(uid, data);
            toast.success(t('general.label.registerEdit'));
            dispatch(setLoading(true));
            history.push('/clients');
        } catch (error) {
            setClients(false);
            dispatch(setLoading(false));
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const addPointsClient = async (value) => {
        dispatch(setLoading(true));
        const point = value.value
        try {
            const res = await usersService.addPoints(uid, point);
            toast.success(t('general.label.addedPoints'));
            history.push('/clients');
            dispatch(setLoading(false));
        } catch (error) {
            dispatch(setLoading(false));
            setPoints(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleDeleteUser = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await usersService.deleteClient(uid)
                toast.success(t('clients.label.clietDeleted'));
                history.push('/clients');
            } else {
                return false;
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleGender = (item) => {
        const newData = formik.values;
        newData.gender = item.value;
        formik.setValues({ ...newData }, true);
    }

    return (
        <>
            <div>
                {/*<ContentHeader title={t('users.label.edit')} buttons={headerButtons} />*/}
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header mb-1">
                            <h3 className="text-danger font-weight-bold">{t('clients.label.editClient')}</h3>
                            <div className="mr-1 card-tools">
                                <Button
                                    type="submit" block
                                    onClick={() => setModalPoints(true)}
                                    className="btn btn-danger" >{t('clients.label.AssingPoints')}</Button>
                            </div>
                            <div className="mr-1 card-tools">
                                <Button
                                    isLoading={clients}
                                    type="submit" block
                                    onClick={handleDeleteUser}
                                    className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                            </div>
                        </div>
                        <div className="p-3">
                            {client ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label>{t('general.label.name')}</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('name')}
                                            type="text"
                                            value={client.name} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.lastName')}</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('lastName')}
                                            type="text"
                                            value={client.lastName} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.dateOfBirth')}</label>
                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('dateOfBirth')}
                                            type="date"
                                            value={prettyOnlyDateOfBirth(client.dateOfBirth)} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.email')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('email')}
                                            type="email"
                                            value={client.email} />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('clients.label.customerPoints')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('pointValue')}
                                            value={client.pointValue} />
                                    </div>

                                    <div className="form-group col-md-6">
                                        <label> {t('general.label.gender')} </label>

                                        <select
                                            className="form-control"
                                            onChange={e => handleGender(genders.find(element => element.value == e.target.value))}
                                            value={formik.values.gender}
                                        >
                                            {genders && genders.length > 0 ? (
                                                genders.map((gender, i) => (
                                                    <option key={"gender-" + i} value={gender.value}>
                                                        {gender.label}
                                                    </option>
                                                ))
                                            ) : null}
                                        </select>

                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">

                                        <Input
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('password')}
                                            type="hidden"
                                            placeholder="Contraseña" />
                                    </div>
                                </div>
                                <div className="card-header -p2">
                                    <h3 className="text-danger font-weight-bold">{t('clients.label.moreInfoClient')}</h3>
                                </div>

                                <div className="form-row mt-3">
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.street')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('street')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.street} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.hometown')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('hometown')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.hometown} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.country')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('country')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.country} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('general.label.postalCode')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('postalCode')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.postalCode} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.numberin')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('internalNumber')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.internalNumber} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.numberout')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps('externalNumber')}
                                            type="text"
                                            value={client.address === null ? '-' : client.address.externalNumber} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label></label>
                                        <div className="card-header -p2">
                                            <h3 className="text-danger font-weight-bold">{t('general.label.babys')}</h3>
                                        </div>                               

                                        <ul className="list-group list-group-flush">
                                            {
                                                client.babies.map((item, index) => {
                                                    return (
                                                        <div key={index} >
                                                            <li className="list-group-item">{client.babies === "" ? '-' : item.name}</li>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="form-group col-md-6">
                                        {
                                            client.babies == "" ? '' : <label>{t('general.label.dateOfBirth')}</label>
                                        }

                                        <ul className="list-group list-group-flush">
                                            {
                                                client.babies.map((item, index) => {
                                                    return (
                                                        <div key={index} >
                                                            <li className="list-group-item">{client.babies === "" ? '-' : prettyOnlyDate(item.dateOfBirth)}</li>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>

                                </div>
                                {client.babies?.length === 0 ? <p className="text-center p-3">Sin bebés registrados para este usuario</p> : null}

                                <div className="card-header -p2">
                                    <h3 className="text-danger font-weight-bold">Direcciones de envío</h3>
                                </div> 
                                {address?.length === 0 ? <p className="text-center p-3">Sin direcciones de envío encontradas.</p> : null}
                                {address?.map((delivery, k)=>(
                                <>
                                <div className="form-row mt-3">
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.street')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`street.${k}`)}
                                            type="text"

                                            value={delivery?.street} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.hometown')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`hometown.${k}`)}
                                            type="text"
                                            value={delivery?.hometown} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.country')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`country.${k}`)}
                                            type="text"
                                            value={delivery?.country} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.postalCode')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`postalCode.${k}`)}
                                            type="text"
                                            value={delivery?.postalCode} />
                                    </div>
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.numberin')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`internalNumber.${k}`)}
                                            type="text"
                                            value={delivery?.internalNumber} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{t('address.label.numberout')}</label>
                                        <Input
                                            disable="true"
                                            formik={formik}
                                            formikFieldProps={formik.getFieldProps(`numberout.${k}`)}
                                            type="text"
                                            value={delivery?.externalNumber} />
                                    </div>
                                </div>
                                </>
                                ))}

                                <div className="row p-2 pt-4">
                                    <div className="col-2">
                                        <Link
                                            to={`/clients/`}
                                            className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                    </div>
                                    <div className="col-sm-2">
                                        <Button
                                            isLoading={clients}
                                            type="submit" block
                                            className="btn btn-danger" > {t('general.buttons.saveChanges')}</Button>
                                    </div>
                                </div>
                            </form> : null}

                        </div>
                    </div>

                    <Modal centered show={modalPoints} onHide={() => setModalPoints(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title className="text-danger font-weight-bold">{t('clients.label.addedPointsClient')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form className="form-horizontal" onSubmit={formikPoints.handleSubmit}>
                                <div className="form-row mt-3">
                                    <div className="form-group col-md-6">
                                        <Input
                                            formik={formikPoints}
                                            formikFieldProps={formikPoints.getFieldProps('value')}
                                            placeholder={t('clients.label.assingToPoints') } />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <Button
                                            isLoading={points}
                                            type="submit" block
                                            className="btn btn-danger" >{t('clients.label.assingPointsCient')}</Button>
                                    </div>
                                </div>
                            </form>
                            <span style={{ fontSize: '12px', color: 'red' }}> {t('clients.label.messageAssingPoints')} </span>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="btn btn-danger" onClick={() => setModalPoints(false)}>
                                {t('general.buttons.cancel')}
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    {/*<div className="card mt-4">*/}
                    {/*    <div className="p-3">*/}
                    {/*        <div className="card-header -p2">*/}
                    {/*            <h3 className="text-danger font-weight-bold">Agregar puntos al cliente</h3>*/}
                    {/*        </div>*/}
                    {/*        <form className="form-horizontal" onSubmit={formikPoints.handleSubmit}>*/}
                    {/*            <div className="form-row mt-3">*/}
                    {/*                <div className="form-group col-md-6">*/}
                    {/*                    <Input*/}
                    {/*                        formik={formikPoints}*/}
                    {/*                        formikFieldProps={formikPoints.getFieldProps('value')}*/}
                    {/*                        placeholder="Ingrese el valor de puntos queu seran asignados" />*/}
                    {/*                </div>*/}
                    {/*                <div className="form-group col-md-6">*/}
                    {/*                    <Button*/}
                    {/*                        isLoading={points}*/}
                    {/*                        type="submit" block*/}
                    {/*                        className="btn btn-danger" > Agregar puntos </Button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </form>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>

        </>
    );
};

export default EditClients;
