import React, {useState, useEffect, useMemo} from 'react';
import {ContentHeader, Button, Input} from '@components';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {setLoading} from '@app/store/reducers/ui';
import {Modal} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Api from '../../utils/axios';

import {
    customStyles,
    paginationComponentOptions
} from '../../utils/table-config';

const Categories = () => {
    const [t] = useTranslation();
    const api = new Api();
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [faqs, setFaqs] = useState([]);
    const history = useHistory();
    const loading = useSelector((state) => state.ui.loading);
    const dispatch = useDispatch();

    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        dispatch(setLoading(true));
        api.getFaqs()
            .then((response) => {
                console.log('response', response?.data);
                setFaqs(response?.data);
                dispatch(setLoading(false));
            })
            .catch((error) => {
                dispatch(setLoading(false));
            });
    }, []);

    const handleButtonClick = (item) => {
        dispatch(setLoading(true));
        api.deleteFaqs(item?.id)
            .then(() => {
                api.getFaqs()
                    .then((response) => {
                        setFaqs(response?.data);
                        dispatch(setLoading(false));
                    })
                    .catch(() => {
                        dispatch(setLoading(false));
                    });
            })
            .catch(() => {
                dispatch(setLoading(false));
            });
    };

    const formikCreate = useFormik({
        initialValues: {
            question: '',
            answer: ''
        },
        validationSchema: Yup.object({
            question: Yup.string().required(t('Campo requerido.')),
            answer: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values, {resetForm}) => {
            dispatch(setLoading(true));
            api.postFaqs({
                question: values?.question,
                answer: values?.answer,
                order: (faqs?.length || 0) + 1
            })
                .then(() => {
                    api.getFaqs()
                        .then((response) => {
                            setFaqs(response?.data);
                            dispatch(setLoading(false));
                            setOpen(false);
                            resetForm();
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    const formikUpdate = useFormik({
        initialValues: {
            id: '',
            question: '',
            answer: ''
        },
        validationSchema: Yup.object({
            question: Yup.string().required(t('Campo requerido.')),
            answer: Yup.string().required(t('Campo requerido.'))
        }),
        onSubmit: (values, {resetForm}) => {
            dispatch(setLoading(true));
            api.putFaqs(values?.id, {
                question: values?.question,
                answer: values?.answer
            })
                .then(() => {
                    api.getFaqs()
                        .then((response) => {
                            setFaqs(response?.data);
                            dispatch(setLoading(false));
                            setOpen2(false);
                            resetForm();
                        })
                        .catch(() => {
                            dispatch(setLoading(false));
                        });
                })
                .catch(() => {
                    dispatch(setLoading(false));
                });
        }
    });

    const columns = useMemo(() => [
        {
            name: '',
            cell: (row) => (
                <>
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => handleButtonClick(row)}
                        id={row.id}
                    >
                        <i className="icon-button fa fa-trash" />
                    </Button>
                    <Button
                        className="mr-1 btn btn-danger"
                        onClick={() => {
                            formikUpdate?.setFieldValue(
                                'question',
                                row?.question
                            );
                            formikUpdate?.setFieldValue('answer', row?.answer);
                            formikUpdate?.setFieldValue('id', row?.id);
                            setOpen2(true);
                        }}
                        id={row.id}
                    >
                        <i className="icon-button fa fa-edit" />
                    </Button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
        {
            name: <b>{t('faqs.label.question')}</b>,
            selector: (row) => row.question,
            sortable: false,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        },
        {
            name: <b>{t('faqs.label.answer')}</b>,
            selector: (row) => row.answer,
            sortable: false,
            style: {
                '&:hover': {
                    minWidth: 'min-content',
                    fontWeight: '600'
                }
            }
        }
    ]);

    return (
        <div id="news-list">
            <div>
                <Modal centered show={open} onHide={() => setOpen(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Nueva pregunta
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formikCreate.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-12">
                                    <label>{t('faqs.label.question')}</label>
                                    <Input
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'question'
                                        )}
                                        type="text"
                                        value={formikCreate?.values?.question}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('faqs.label.answer')}
                                    </label>
                                    <Input
                                        formik={formikCreate}
                                        formikFieldProps={formikCreate.getFieldProps(
                                            'answer'
                                        )}
                                        type="text"
                                        value={formikCreate?.values?.answer}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal centered show={open2} onHide={() => setOpen2(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title className="text-danger font-weight-bold">
                            Editar pregunta
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form
                            className="form-horizontal"
                            onSubmit={formikUpdate.handleSubmit}
                        >
                            <div className="form-row mt-3 d-flex align-items-center">
                                <div className="form-group col-md-12">
                                    <label>{t('faqs.label.question')}</label>
                                    <Input
                                        formik={formikUpdate}
                                        formikFieldProps={formikUpdate.getFieldProps(
                                            'question'
                                        )}
                                        type="text"
                                        value={formikUpdate?.values?.question}
                                    />
                                    <label style={{marginTop: '20px'}}>
                                        {t('faqs.label.answer')}
                                    </label>
                                    <Input
                                        formik={formikUpdate}
                                        formikFieldProps={formikUpdate.getFieldProps(
                                            'answer'
                                        )}
                                        type="text"
                                        value={formikUpdate?.values?.answer}
                                    />
                                </div>
                                <div className="form-group col-md-12">
                                    <Button
                                        // isLoading={points}
                                        type="submit"
                                        block
                                        className="btn btn-danger"
                                    >
                                        Guardar
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="btn btn-danger"
                            onClick={() => setOpen2(false)}
                        >
                            {t('general.buttons.cancel')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="col-sm-12 d-flex justify-content-between align-items-center">
                <ContentHeader title={t('faqs.label.title')} />
                <Button
                    type="button"
                    onClick={() => {
                        setOpen(true);
                    }}
                    className="btn btn-danger ml-2"
                >
                    <i className="icon-button fa fa-plus mr-2" />
                    Nueva pregunta
                </Button>
            </div>
            {faqs && faqs.length > 0 ? (
                <DataTable
                    data={faqs}
                    customStyles={customStyles}
                    progressPending={loading}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    // subHeaderComponent={subHeaderComponentMemo}
                    noDataComponent="No se encuentran registros"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    highlightOnHover
                    columns={columns}
                />
            ) : (
                <div>
                    <h1 className="text-center">
                        {t('general.label.noRegisters')}
                    </h1>
                </div>
            )}
        </div>
    );
};

export default Categories;
