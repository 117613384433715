import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
// Modules
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import PrivacyPolicy from '@modules/privacy-policy/PrivacyPolicy';
import TermsConditions from '@modules/terms-conditions/TermsConditions';
// Hooks
import { useWindowSize } from '@app/hooks/useWindowSize';
// Helpers
import { calculateWindowSize } from '@app/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { setWindowSize } from '@app/store/reducers/ui';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import animationData from './animation/animation.json'
import Lottie from 'react-lottie'
import './App.scss';

const loadingView = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
)


const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const App = () => {
    const windowSize = useWindowSize();
    const screenSize = useSelector((state) => state.ui.screenSize);
    //const [loading, setLoading] = useState(false)
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();

    useEffect(() => {
        const size = calculateWindowSize(windowSize.width);
        if (screenSize !== size) {
            dispatch(setWindowSize(size));
        }
    }, [windowSize]);

    return (
        <Router>
            <React.Suspense fallback={loadingView}>
                <Switch>
                    <PublicRoute exact path="/login">
                        <Login />
                    </PublicRoute>
                    <PublicRoute exact path="/register">
                        <Register />
                    </PublicRoute>
                    <PublicRoute exact path="/forgot-password">
                        <ForgetPassword />
                    </PublicRoute>
                    <PublicRoute exact path="/recover-password">
                        <RecoverPassword />
                    </PublicRoute>
                    <PublicRoute exact path="/app/terms-conditions">
                        <TermsConditions />
                    </PublicRoute>
                    <PublicRoute exact path="/app/privacy-policy">
                        <PrivacyPolicy />
                    </PublicRoute>
                    <PublicRoute exact path="/callback">
                        <h1>Callback</h1>
                    </PublicRoute>
                    <PrivateRoute path="/">
                        <Main />
                    </PrivateRoute>
                </Switch>
            </React.Suspense>

            <div id="lottie-wrapper" style={loading ? { display: 'block' } : { display: 'none' }}>
                <Lottie
                    id="lottie-animation"
                    className="lottie-animation"
                    options={lottieOptions}
                />
            </div>
        </Router>
    );
};

export default App;
