import React from 'react';
import { CSVLink, CSVDownload } from "react-csv";

const ExportToCsv = ({ data, fileName, headers }) => {

    return (
        <CSVLink
            data={data}
            headers={headers}
            filename={fileName}
        >Exportar a CSV {/*<FontAwesomeIcon icon={faFileCsv} size="2x" /> */}</CSVLink>
    )
}

export default ExportToCsv;