import Api from '../utils/axios';
const api = new Api();

export const filterUsers = async () => {
    const response = await api.filterUsers()
    return response;
}

export const filterBabies = async () => {
    const res = await api.filterBabies();
    return res;
}

export const filterStore = async (startDate, endDate) => {
    const res = await api.filterStore(startDate, endDate)
    return res;
}

export const ticketsStore = async (startDate, endDate) => {
    const res = await api.ticketsStore(startDate, endDate)
    return res;
}

export const filterRejected = async (startDate, endDate) => {
    const res = await api.filterRejected(startDate, endDate);
    return res;
}

export const filterSeen = async (startDate, endDate) => {
    const res = await api.filterSeen(startDate, endDate);
    return res;

}

export const filterPoints = async (startDate, endDate) => {
    const res = await api.filterPoints(startDate, endDate);
    return res;

}

export const filterSku = async (startDate, endDate) => {
    const res = await api.filterSku(startDate, endDate)
    return res;
}

export const promotionsInformation = async (type) => {
    return await api.getPromotionInformation(type)
}

export const promotionsReports = async (type) => {
    return await api.getPromotionReports(type)
}
