import React, { useState, useEffect, useMemo } from 'react';
import { Button, FilterComponent, SmallBox, ExportToCsv } from '@components';
import { useTranslation } from 'react-i18next';
import * as reportService from '../../services/reports';
import prettyDate, { prettyOnlyDateOfBirth } from '../../utils/pretty-date'
import { customStyles, paginationComponentOptions } from '../../utils/table-config'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

const locale = {
    "format": "MM/DD/YYYY",
    "separator": " - ",
    "applyLabel": "Filtrar",
    "cancelLabel": "Cancelar",
    "fromLabel": "From",
    "toLabel": "To",
    "customRangeLabel": "Custom",
    "weekLabel": "W",
    "daysOfWeek": [
        "Do",
        "Lu",
        "Ma",
        "Mi",
        "Ju",
        "Vi",
        "Sa"
    ],
    "monthNames": [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ],
    "firstDay": 1
}

const ranges = {
    'Hoy': [moment(), moment()],
    'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Ultimos 7 días': [moment().subtract(6, 'days'), moment()],
    'Ultimos 30 días': [moment().subtract(29, 'days'), moment()],
    'Este mes': [moment().startOf('month'), moment().endOf('month')],
    'Últtimo mes': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
}

const headersExport = [
    { label: 'GUID Gigya', key: 'clientId' },
    { label: 'Correo electrónico', key: 'email' },
    { label: 'Puntos Asignados', key: 'pointRedeemed' },
    { label: 'Código', key: 'code' },
    { label: 'Fecha', key: 'redeemedDate' }
]

const Promotions = () => {
    const [t] = useTranslation();
    const [promotions, setPromotions] = useState([])
    const [promotionsInformation, setPromotionsInformation] = useState({
        tickets: 0,
        compras: 0.0,
        users: 0,
        promotions: 0,
    })
    const loading = useSelector(state => state.ui.loading)
    const dispatch = useDispatch();
    const columns = useMemo(() => [
        {
            name: <b>{t('promotions.table.uuid')}</b>,
            selector: row => row.clientId === null ? '-' : row.clientId,
            sortable: true,
        },
        {
            name: <b>{t('general.label.email')}</b>,
            selector: row => row.email === null ? '-' : row.email,
            sortable: true,
        },
        {
            name: <b>{t('promotions.table.points')}</b>,
            selector: row => row.pointRedeemed === null ? '-' : row.pointRedeemed,
            sortable: true,
        },
        {
            name: <b>{t('promotions.table.code')}</b>,
            selector: row => row.code,
            cell: (row) => <span> {row.code}</span>,
            sortable: true,
        },
        {
            name: <b>{t('promotions.table.date')}</b>,
            selector: row => row.redeemedDate,
            cell: (row) => <span> {prettyDate(row.redeemedDate)}</span>,
            sortable: true,
        },
    ]);

    // Hooks para el filtro de la tabla
    const [filterCode, setFilterCode] = useState('');
    const [filterAuthor, setFilterAuthor] = useState('');
    // Paginations Reset
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    // Funcion principal para el filtro multiple
    const filteredItems = promotions.filter(
        item => (item.code && item.code.toLowerCase().includes(filterCode.toLowerCase())) &&
            (item.email && item.email.toLowerCase().includes(filterAuthor.toLowerCase()))
    );

    const handleCallback = async (start, end, label) => {
        setStartDate(start);
        setEndDate(end);
    }

    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterAuthor) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterAuthor('');
            }

            if (filterCode) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterCode('');
            }
        };

        return (
            <div className='d-flex p-2'>
                <div className="p-2">
                    <ExportToCsv data={promotions} headers={headersExport} fileName="reports_points.csv" />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Código' onFilter={e => setFilterCode(e.target.value)} filterText={filterCode} />
                </div>
                <div className="p-2">
                    <FilterComponent placeholder='Filtrar por Email' onFilter={e => setFilterAuthor(e.target.value)} filterText={filterAuthor} />
                </div>
                <div className="p-2">
                    <Button className="btn btn-danger" type="button" onClick={handleClear}>
                        Limpiar
                    </Button>
                </div>
            </div>
        );
    }, [filterAuthor, filterCode, resetPaginationToggle]);

    useEffect(() => {
        handleFilterStatick();
    }, [])

    const handleFilterStatick = async () => {
        dispatch(setLoading(true));
        try {

            const promotionInformation = await reportService.promotionsInformation(0);
            setPromotionsInformation(promotionInformation.data)

            const responsePromotion = await reportService.promotionsReports(0);
            setPromotions(responsePromotion.data)

            dispatch(setLoading(false));

        } catch (error) {
            console.log(error.message || 'Failed');
            dispatch(setLoading(false));
        }
    }

    return (
        <div className="container-fluid">

            <div className="form-row">
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={promotionsInformation.users}
                        title={t('promotions.box.users')}
                        type="info"
                        icon="ion-android-people"
                        withPercentage
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={promotionsInformation.points}
                        title={t('promotions.box.points')}
                        type="success"
                        withPercentage
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={promotionsInformation.codes}
                        title={t('promotions.box.codes')}
                        type="danger"
                        icon="ion-android-people"
                        withPercentage
                    />
                </div>
                <div className="col-lg-3 col-6">
                    <SmallBox
                        count={promotionsInformation.countPromotions}
                        title={t('promotions.box.promotion')}
                        type="warning"
                        icon="ion-android-people"
                        withPercentage
                    />
                </div>
            </div>

            <div id="news-list">

                {promotions && promotions.length > 0 ?
                    <DataTable
                        data={filteredItems}
                        customStyles={customStyles}
                        progressPending={loading}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noDataComponent="No se encuentran registros"
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        highlightOnHover={true}
                        columns={columns}
                    />
                    : <div>
                        <h1 className="text-center">{t('general.label.noRegisters')}</h1>
                    </div>}

            </div>
        </div>
    );


};

export default Promotions;