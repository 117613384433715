import Api from '../utils/axios';
const api = new Api();

export const getTags = async () => {
    const tags = await api.getTags()
    //console.log(tags);
    return tags;
}

export const createTag = async (data) => {
    const CreateTag = await api.createTag();
    console.log(CreateTag)
    return CreateTag;
}

export const getTagById = async (id) => {
    const response = await api.getTagById(id);
    console.log(response)
    return response.data;
}

export const updateTags = async (id, data) => {
    const updateTag = await api.updateTags(id, data);
    console.log(updateTag)
    return updateTag;
}

export const deleteTag = async (id) => {
    const response = await api.deleteTag(id);
    return response.data
}